import React, { useEffect, useState } from "react";
import MetricsDataTableContainerScrum from "./MetricsDataTableContainerScrum";
import MetricsDataTableContainerFlow from "./MetricsDataTableContainerFlow";

import "./MetricsDataTable.css";
import { Iteration, Period, UserStory } from "rally_core/build";
import { RenderLogger } from "../utils/RenderLogger";
import {
    initializeScrumMetricsObject,
    PeriodVelocityMetrics,
    ScrumMetrics,
    SummaryScrumMetrics,
    SummaryVelocityMetrics,
    VelocityMetrics,
} from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { calculateSummaryMetricsForPeriod, FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import {
    BacklogHealthMetrics,
    calculateBacklogHealthMetrics,
    calculateSummaryBacklogMetrics,
} from "pdo_metrics_calculator/build//backlogHealthCalculator";
import moment from "moment";

const renderLogger = new RenderLogger("PeriodSelector");

function debug(props: MetricsDataTableInputDataProps): string {
    const iterationLength = props.iterations ? props.iterations.length : 0;
    const periodsLength = props.metricsPeriods ? props.metricsPeriods.length : 0;
    const previousPeriodsLength = props.previousMetricsPeriods ? props.previousMetricsPeriods.length : 0;
    const selectedPeriod =
        props.metricsPeriods.length > 0
            ? moment(props.metricsPeriods[2].startDate).format("l") +
              " - " +
              moment(props.metricsPeriods[2].startDate).format("l")
            : "not selected";
    const userStoriesLength = props.userStories ? props.userStories.length : 0;
    const readyUserStoriesLength = props.readyUserStories ? props.readyUserStories.length : 0;
    const committedUserStoriesLength = props.committedUserStories ? props.committedUserStories.length : 0;

    return `selected planning style:${props.selectedPlanningStyle}
    iteration length: ${iterationLength}
    periods length: ${periodsLength}
    previous periods length: ${previousPeriodsLength}
    selected period: ${selectedPeriod}
    User Stories Length: ${userStoriesLength}
    Ready User Stories Length: ${readyUserStoriesLength}
    Committed User Stories Length: ${committedUserStoriesLength}`;
}

function MetricsDataTableContainerJSX(props: MetricsDataTableInputDataProps): JSX.Element {
    renderLogger.log(debug(props));

    const emptyScrumMetrics: ScrumMetrics[] = [];
    const emptyVelocityMetrics: VelocityMetrics[] = [];
    const emptyFlowMetrics: FlowMetrics[] = [];
    const emptyBacklogHealthMetrics: BacklogHealthMetrics[] = [];

    const [scrumMetrics, setScrumMetrics] = useState(emptyScrumMetrics);
    const [velocityMetrics, setVelocityMetrics] = useState(emptyVelocityMetrics);
    const [flowMetrics, setFlowMetrics] = useState(emptyFlowMetrics);
    const [backlogHealthMetrics, setBacklogHealthMetrics] = useState(emptyBacklogHealthMetrics);

    const metricsCalculated: (
        scrumMetrics: ScrumMetrics[],
        velocityMetrics: VelocityMetrics[],
        flowMetrics: FlowMetrics[],
        backlogHealthMetrics: BacklogHealthMetrics[]
    ) => void = props.metricsCalculated;

    useEffect(() => {
        (async () => {
            function readyToStartDisplayingScrumMetrics() {
                return (
                    props.selectedPlanningStyle === "Scrum" && props.metricsPeriods && props.metricsPeriods.length > 0
                );
            }

            function readyToStartDisplayingFlowMetrics() {
                return (
                    props.selectedPlanningStyle === "Flow" && props.metricsPeriods && props.metricsPeriods.length > 0
                );
            }

            if (readyToStartDisplayingScrumMetrics()) {
                const scrumMetricsArray = createScrumMetricsArray();
                const flowMetricsArray = createFlowMetricsArray(props.metricsPeriods, props.userStories);
                const backlogHealthMetricsArray = createBacklogHealthMetricsArray(
                    props.metricsPeriods,
                    props.previousMetricsPeriods,
                    props.userStories,
                    props.readyUserStories
                );

                setScrumMetrics(scrumMetricsArray);
                setFlowMetrics(flowMetricsArray);
                setBacklogHealthMetrics(backlogHealthMetricsArray);
                metricsCalculated(scrumMetricsArray, emptyVelocityMetrics, flowMetricsArray, backlogHealthMetricsArray);
            } else if (readyToStartDisplayingFlowMetrics()) {
                const velocityMetricsArray = createVelocityMetricsArray();
                const flowMetricsArray = createFlowMetricsArray(props.metricsPeriods, props.userStories);
                const backlogHealthMetricsArray = createBacklogHealthMetricsArray(
                    props.metricsPeriods,
                    props.previousMetricsPeriods,
                    props.userStories,
                    props.readyUserStories
                );

                setVelocityMetrics(velocityMetricsArray);
                setFlowMetrics(flowMetricsArray);
                setBacklogHealthMetrics(backlogHealthMetricsArray);
                metricsCalculated(emptyScrumMetrics, velocityMetricsArray, flowMetricsArray, backlogHealthMetricsArray);
            }

            function createScrumMetricsArray() {
                const metricsArray: ScrumMetrics[] = [];
                for (let i = 0; i < 4; i++) {
                    if (i < 3) {
                        metricsArray.push(
                            initializeScrumMetricsObject(
                                props.metricsPeriods[i] as Iteration,
                                props.userStories,
                                props.committedUserStories[i],
                                props.previousMetricsPeriods[i] as Iteration[],
                                props.readyUserStories[i]
                            )
                        );
                    } else if (i === 3) {
                        metricsArray.push(new SummaryScrumMetrics([metricsArray[0], metricsArray[1], metricsArray[2]]));
                    }
                }
                return metricsArray;
            }

            function createVelocityMetricsArray() {
                const metricsArray: VelocityMetrics[] = [];
                for (let i = 0; i < 4; i++) {
                    if (i < 3) {
                        metricsArray.push(
                            new PeriodVelocityMetrics(
                                props.metricsPeriods[i],
                                props.previousMetricsPeriods[i],
                                props.userStories,
                                props.readyUserStories[i]
                            )
                        );
                    } else if (i === 3) {
                        metricsArray.push(
                            new SummaryVelocityMetrics([metricsArray[0], metricsArray[1], metricsArray[2]])
                        );
                    }
                }
                return metricsArray;
            }

            function createFlowMetricsArray(metricsPeriods: Period[], userStories: UserStory[]): FlowMetrics[] {
                const flowMetricsArray: FlowMetrics[] = [];
                for (const item of metricsPeriods) {
                    flowMetricsArray.push(calculateSummaryMetricsForPeriod(item, userStories));
                }

                return flowMetricsArray;
            }

            function createBacklogHealthMetricsArray(
                metricsPeriods: Period[],
                previousPeriods: Period[][],
                userStories: UserStory[],
                readyStories: UserStory[][]
            ) {
                const backlogHealthMetricsArray: BacklogHealthMetrics[] = [];
                for (let i = 0; i < metricsPeriods.length - 1; i++) {
                    backlogHealthMetricsArray.push(
                        calculateBacklogHealthMetrics(
                            metricsPeriods[i],
                            previousPeriods[i],
                            userStories,
                            readyStories[i]
                        )
                    );
                }

                const summary = calculateSummaryBacklogMetrics(backlogHealthMetricsArray);

                backlogHealthMetricsArray.push(summary);

                return backlogHealthMetricsArray;
            }
            // eslint-disable-next-line
        })()}, [
        props.selectedPlanningStyle,
        props.metricsPeriods,
        props.previousMetricsPeriods,
        props.userStories,
        props.readyUserStories,
        props.committedUserStories,
        metricsCalculated,
    ]);

    function selectTableBasedOnPlanningStyle() {
        if (props.selectedPlanningStyle === "Scrum") {
            return (
                <React.Fragment>
                    <MetricsDataTableContainerScrum
                        periodLength={props.periodLength}
                        scrumMetrics={scrumMetrics}
                        flowMetrics={flowMetrics}
                        backlogHealthMetrics={backlogHealthMetrics}
                        selectedPlanningStyle={props.selectedPlanningStyle}
                        projectId={props.projectId}
                        metricsPeriods={props.metricsPeriods}
                    />
                </React.Fragment>
            );
        } else if (props.selectedPlanningStyle === "Flow" && props.metricsPeriods.length > 0) {
            return (
                <React.Fragment>
                    <MetricsDataTableContainerFlow
                        periodLength={props.periodLength}
                        velocityMetrics={velocityMetrics}
                        flowMetrics={flowMetrics}
                        backlogHealthMetrics={backlogHealthMetrics}
                        selectedPlanningStyle={props.selectedPlanningStyle}
                        projectId={props.projectId}
                        metricsPeriods={props.metricsPeriods}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div test-dataid="NoMetricsDataTable" />
                </React.Fragment>
            );
        }
    }

    return selectTableBasedOnPlanningStyle();
}

export class MetricsDataTableInputDataProps {
    periodLength: number;
    projectId: number;
    selectedPlanningStyle: string;
    iterations: Iteration[];
    metricsPeriods: Period[];
    previousMetricsPeriods: Period[][];
    userStories: UserStory[];
    committedUserStories: UserStory[][];
    readyUserStories: UserStory[][];
    metricsCalculated: (
        scrumMetrics: ScrumMetrics[],
        velocityMetrics: VelocityMetrics[],
        flowMetrics: FlowMetrics[],
        backlogHealthMetrics: BacklogHealthMetrics[]
    ) => void;

    constructor(
        periodLength: number,
        projectId: number,
        selectedPlanningStyle = "none",
        iterations: Iteration[] = [],
        metricsPeriods: Period[] = [],
        previousMetricsPeriods: Period[][] = [],
        userStories: UserStory[] = [],
        committedUserStories: UserStory[][] = [],
        readyUserStories: UserStory[][] = [],
        metricsCalculated: (
            scrumMetrics: ScrumMetrics[],
            velocityMetrics: VelocityMetrics[],
            flowMetrics: FlowMetrics[],
            backlogHealthMetrics: BacklogHealthMetrics[]
        ) => void = () => {
            return;
        }
    ) {
        this.periodLength = periodLength;
        this.projectId = projectId;
        this.selectedPlanningStyle = selectedPlanningStyle;
        this.iterations = iterations;
        this.metricsPeriods = metricsPeriods;
        this.previousMetricsPeriods = previousMetricsPeriods;
        this.userStories = userStories;
        this.committedUserStories = committedUserStories;
        this.readyUserStories = readyUserStories;
        this.metricsCalculated = metricsCalculated;
    }
}

const MetricsDataTableContainer = React.memo(MetricsDataTableContainerJSX);

export default MetricsDataTableContainer;

import React, { useRef } from "react";
import { formatDecimalPlaces } from "../utils/StringFunctions";
import { RenderLogger } from "../utils/RenderLogger";

function changeUndefinedToNeutralColor(metric: number | undefined) {
    if (!metric) {
        return " insight-neutral";
    }

    return "";
}

function MetricBannerWithTrendJSX(props: MetricsWithTrendProps): JSX.Element {
    const renderLogger = useRef(new RenderLogger("MetricBannerWithTrend - " + props.name));

    renderLogger.current.log();
    function trendCharacter(metric: number | undefined, previousMetric: number | undefined) {
        if (metric === undefined) {
            return "";
        } else if (previousMetric !== undefined) {
            if (metric === previousMetric) {
                return "--";
            } else if (metric > previousMetric) {
                return "↑";
            } else {
                return "↓";
            }
        } else {
            return "--";
        }
    }

    function trendStyle(metric: number | undefined, previousMetric: number | undefined, greenTrend: number) {
        const trend = trendCharacter(metric, previousMetric);

        if (trend === "↑" && greenTrend > 0) {
            return "insight-good";
        } else if (trend === "↑" && greenTrend <= 0) {
            return "insight-bad";
        } else if (trend === "↓" && greenTrend > 0) {
            return "insight-bad";
        } else if (trend === "↓" && greenTrend <= 0) {
            return "insight-good";
        } else {
            return "insight-neutral";
        }
    }

    function formatMetric(metric: number | undefined, numberOfDecimalPlaces: number): string {
        if (!metric) {
            return "--";
        }
        return formatDecimalPlaces(metric, numberOfDecimalPlaces);
    }

    return (
        <div className="banner-item">
            <div className="banner-visual">
                <span className={`banner-metric${changeUndefinedToNeutralColor(props.metric)}`} data-testid="metric">
                    {formatMetric(props.metric, props.numberOfDecimalPlaces)}
                </span>
                <span
                    className={`banner-metric-trend ${trendStyle(
                        props.metric,
                        props.previousMetric,
                        props.greenTrend
                    )}`}
                    data-testid="trend"
                >
                    {trendCharacter(props.metric, props.previousMetric)}
                </span>
            </div>
            <div className="banner-label">
                <span data-testid="name">{props.name}</span>
            </div>
        </div>
    );
}

export interface MetricsWithTrendProps {
    name: string;
    metric: number | undefined;
    previousMetric: number | undefined;
    numberOfDecimalPlaces: number;
    greenTrend: number;
}

const MetricBannerWithTrend = React.memo(MetricBannerWithTrendJSX);
export default MetricBannerWithTrend;

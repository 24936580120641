import React from "react";
import { formatDecimalPlaces } from "../utils/StringFunctions";
import { isEqual } from "lodash";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "../tippy.css";
import Tippy from "@tippyjs/react";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";

function displayMetric(
    m: BacklogHealthMetrics,
    func: (m: BacklogHealthMetrics) => number,
    dec: number //number of decimal places
) {
    if (!m || isEqual(m, {})) {
        return "--";
    }

    const val = func(m);
    return formatDecimalPlaces(val, dec);
}

export default function MetricsDataTableBacklog(props: MetricsDataTableBacklogProps): JSX.Element {
    return (
        <div className="metrics-data-table">
            <div className="iterationmetrics-sectionheader">Backlog Metrics</div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            How many stories are <span className="tooltip-highlight">marked ready</span> in your
                            backlog. If a story is not marked ready it will not be included in the calculation.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span># of Ready Stories</span>
                </Tippy>
            </div>
            <div id="readyStories-0" data-testid="readyStories-0" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[0], (m) => m.countOfStoriesReady(), 0)}
            </div>
            <div id="readyStories-1" data-testid="readyStories-1" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[1], (m) => m.countOfStoriesReady(), 0)}
            </div>
            <div id="readyStories-2" data-testid="readyStories-2" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[2], (m) => m.countOfStoriesReady(), 0)}
            </div>
            <div id="readyStories-3" data-testid="readyStories-3" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[3], (m) => m.countOfStoriesReady(), 1)}
            </div>
            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            How many stories are <span className="tooltip-highlight">marked ready</span> in your
                            backlog. If a story is not marked ready it will not be included in the calculation.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span># of Ready Points</span>
                </Tippy>
            </div>
            <div id="readyPoints-0" data-testid="readyPoints-0" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[0], (m) => m.pointsReady, 0)}
            </div>
            <div id="readyPoints-1" data-testid="readyPoints-1" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[1], (m) => m.pointsReady, 0)}
            </div>
            <div id="readyPoints-2" data-testid="readyPoints-2" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[2], (m) => m.pointsReady, 0)}
            </div>
            <div id="readyPoints-3" data-testid="readyPoints-3" className="data-numeric">
                {displayMetric(props.backlogHealthMetrics[3], (m) => m.pointsReady, 1)}
            </div>

            <div className="row-label thick-border-bottom data-tooltip">
                <Tippy
                    content={
                        <span>
                            How many metricsPeriods worth of stories are{" "}
                            <span className="tooltip-highlight">marked ready</span> in your backlog. If a story is not
                            marked ready it will not be included in the calculation. Sum of Points for Stories Marked
                            Ready / Average Velocity (This period most recently completed and previous 2)
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span># of Ready Periods</span>
                </Tippy>
            </div>
            <div id="readyPeriods-0" data-testid="readyPeriods-0" className="data-numeric thick-border-bottom ">
                {displayMetric(props.backlogHealthMetrics[0], (m) => m.periodsReady, 1)}
            </div>
            <div id="readyPeriods-1" data-testid="readyPeriods-1" className="data-numeric thick-border-bottom ">
                {displayMetric(props.backlogHealthMetrics[1], (m) => m.periodsReady, 1)}
            </div>
            <div id="readyPeriods-2" data-testid="readyPeriods-2" className="data-numeric thick-border-bottom ">
                {displayMetric(props.backlogHealthMetrics[2], (m) => m.periodsReady, 1)}
            </div>
            <div id="readyPeriods-3" data-testid="readyPeriods-3" className="data-numeric thick-border-bottom ">
                {displayMetric(props.backlogHealthMetrics[3], (m) => m.periodsReady, 1)}
            </div>
            <div></div>
        </div>
    );
}

export interface MetricsDataTableBacklogProps {
    backlogHealthMetrics: BacklogHealthMetrics[];
}

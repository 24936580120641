import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ChartGenerator } from "./charts/ChartGenerator";
import C3GaugeChartWriter from "./charts/C3GaugeChartWriter";
import { AppProps } from "./AppProps";

export class PdoMetricsDashboard extends HTMLElement {
    connectedCallback(): void {
        /*
        IMPORTANT:
        c3 chart elements must be injected into app because they interfere with Jest testing
        because JSDOM does not implement SVGPathElement.
         */
        ChartGenerator.configureGaugeChartWriter(new C3GaugeChartWriter());

        const props: AppProps = new AppProps();

        ReactDOM.render(
            <React.StrictMode>
                <App {...props} />
            </React.StrictMode>,
            this
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    }
}
customElements.define("pdo-metrics-dashboard", PdoMetricsDashboard);

import { useEffect, useRef } from "react";
import { DataRetrievalEvent, DataRetrievalEventManager } from "../utils/DataRetrievalEvent";
import { Iteration, UserStory } from "rally_core/build";
import { RenderLogger } from "../utils/RenderLogger";

export function useAsyncDataRetrieval(props: AsyncDataRetrievalProps): void {
    const renderLogger = useRef(new RenderLogger("useAsyncDataRetrieval - " + props.description));

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            renderLogger.current.log();
        }

        let dataRetrievalEvent = new DataRetrievalEvent(props.description);
        if (props.recordDataRetrievalEvent) {
            dataRetrievalEvent = DataRetrievalEventManager.getInstance().addDataRetrievalEvent(props.description);
        }

        props
            .asyncDataRetrievalFunction()
            .then((data) => {
                if (isMounted) {
                    dataRetrievalEvent.completeEventSuccessfully();
                    props.setGenerationTs(new Date());
                    props.onSuccess(data);
                }
            })
            .catch((error) => {
                if (isMounted) {
                    dataRetrievalEvent.completeEventWithError(error);
                    props.setGenerationTs(new Date());
                    props.onError(error);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [props]);
}

export class AsyncDataRetrievalProps {
    asyncDataRetrievalFunction: () => Promise<Iteration[] | UserStory[] | UserStory[][]> = () =>
        Promise.resolve([] as Iteration[]);
    onSuccess: (data: Iteration[] | UserStory[] | UserStory[][]) => void = () => {
        return;
    };
    onError: (error: Error) => void = () => {
        return;
    };
    description = "The Async Data Retrieval Props Description was Never initialized";
    recordDataRetrievalEvent = false;

    setGenerationTs: (v: Date) => void = () => {
        return;
    };
}

import React from "react";
import { isEqual } from "lodash";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "../tippy.css";
import { formatDecimalPlaces, formatPercentageWithDetails, NO_DATA_AVAILABLE } from "../utils/StringFunctions";
import { isNaNOrInfinity } from "../utils/NumberFunctions";

function noDataAvailable(scrumMetric: VelocityMetrics): boolean {
    return !scrumMetric || isEqual(scrumMetric, {});
}

export function acceptedStoryPercentage(scrumMetric: ScrumMetrics): string {
    if (noDataAvailable(scrumMetric)) {
        return NO_DATA_AVAILABLE;
    }

    return formatPercentageWithDetails(
        scrumMetric.acceptedStoryPercentage(),
        scrumMetric.acceptedStoryCount(),
        scrumMetric.committedStoryCount()
    );
}

export function acceptedPointPercentage(scrumMetric: ScrumMetrics): string {
    if (noDataAvailable(scrumMetric)) {
        return NO_DATA_AVAILABLE;
    }

    return formatPercentageWithDetails(
        scrumMetric.acceptedPointPercentage(),
        scrumMetric.acceptedPointCount(),
        scrumMetric.committedPointCount()
    );
}

export function committedPointPercentage(scrumMetric: ScrumMetrics): string {
    if (noDataAvailable(scrumMetric)) {
        return NO_DATA_AVAILABLE;
    }
    return formatPercentageWithDetails(
        scrumMetric.committedPointPercentage(),
        scrumMetric.committedPointsAccepted(),
        scrumMetric.committedPointCount()
    );
}

export function velocityVariation(scrumMetric: VelocityMetrics): string {
    if (noDataAvailable(scrumMetric)) {
        return NO_DATA_AVAILABLE;
    }

    if (isNaNOrInfinity(scrumMetric.velocityVariation())) {
        return NO_DATA_AVAILABLE;
    }

    return `${formatDecimalPlaces(scrumMetric.velocityVariation() * 100, 1)}%`;
}

export default function MetricsDataTableScrum(props: MetricsDataTableScrumProps): JSX.Element {
    return (
        <div className="metrics-data-table">
            <div
                className="iterationmetrics-sectionheader"
                id="sectionHeaderVelocityMetrics"
                data-testid="sectionHeaderVelocityMetrics"
            >
                Scrum Metrics
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            The number of stories accepted compared to the number of stories committed to. Commitment is
                            defined as the stories in the backlog 1 day after the start of the iteration.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Accepted Story %</span>
                </Tippy>
            </div>
            <div className="data-numeric" id="acceptedStoryPercent-0" data-testid="acceptedStoryPercent-0">
                {acceptedStoryPercentage(props.scrumMetrics[0])}
            </div>
            <div className="data-numeric" id="acceptedStoryPercent-1" data-testid="acceptedStoryPercent-1">
                {acceptedStoryPercentage(props.scrumMetrics[1])}
            </div>
            <div className="data-numeric" id="acceptedStoryPercent-2" data-testid="acceptedStoryPercent-2">
                {acceptedStoryPercentage(props.scrumMetrics[2])}
            </div>
            <div className="data-numeric" id="acceptedStoryPercent-3" data-testid="acceptedStoryPercent-3">
                {acceptedStoryPercentage(props.scrumMetrics[3])}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            The number of points accepted compared to the number of points committed to. Commitment is
                            defined as the stories in the backlog 1 day after the start of the iteration.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Accepted Point %</span>
                </Tippy>
            </div>

            <div className="data-numeric" id="acceptedPointPercent-0" data-testid="acceptedPointPercent-0">
                {acceptedPointPercentage(props.scrumMetrics[0])}
            </div>
            <div className="data-numeric" id="acceptedPointPercent-1" data-testid="acceptedPointPercent-1">
                {acceptedPointPercentage(props.scrumMetrics[1])}
            </div>
            <div className="data-numeric" id="acceptedPointPercent-2" data-testid="acceptedPointPercent-2">
                {acceptedPointPercentage(props.scrumMetrics[2])}
            </div>
            <div className="data-numeric" id="acceptedPointPercent-3" data-testid="acceptedPointPercent-3">
                {acceptedPointPercentage(props.scrumMetrics[3])}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            Measures completion percentage (points) of the specific user stories that were committed to.
                            Commitment is defined as the stories in the backlog 1 day after the start of the iteration.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Commitment %</span>
                </Tippy>
            </div>
            <div className="data-numeric" id="commitmentPercent-0" data-testid="commitmentPercent-0">
                {committedPointPercentage(props.scrumMetrics[0])}
            </div>
            <div className="data-numeric" id="commitmentPercent-1" data-testid="commitmentPercent-1">
                {committedPointPercentage(props.scrumMetrics[1])}
            </div>
            <div className="data-numeric" id="commitmentPercent-2" data-testid="commitmentPercent-2">
                {committedPointPercentage(props.scrumMetrics[2])}
            </div>
            <div className="data-numeric" id="commitmentPercent-3" data-testid="commitmentPercent-3">
                {committedPointPercentage(props.scrumMetrics[3])}
            </div>

            <div className="row-label data-tooltip bottom-row">
                <Tippy
                    content={
                        <span>
                            Velocity Standard Deviation of last 3 Iterations / Average Velocity Last 3 Iterations
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Velocity Variation %</span>
                </Tippy>
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-0" data-testid="velocityVariation-0">
                {velocityVariation(props.scrumMetrics[0])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-1" data-testid="velocityVariation-1">
                {velocityVariation(props.scrumMetrics[1])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-2" data-testid="velocityVariation-2">
                {velocityVariation(props.scrumMetrics[2])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-3" data-testid="velocityVariation-3">
                --
            </div>
        </div>
    );
}

export interface MetricsDataTableScrumProps {
    scrumMetrics: ScrumMetrics[];
}

import React from "react";
import { Period } from "rally_core/build";
import { isEqual } from "lodash";
import moment from "moment";
import { PERIOD_END_DATE_UNDEFINED } from "../AppProps";

export function formatPeriodRange(period: Period): string {
    if (!period || isEqual(period, {})) {
        return "";
    }

    return `${moment(period.startDate).format("l")} - ${moment(period.endDate).format("l")}`;
}

export function formatPeriodSummary(metricsPeriods: Period[]): string {
    if (isEqual(metricsPeriods, [])) {
        return "";
    }

    return "Summary";
}

export function MetricsDataTableHeader(props: MetricsDataTableHeaderProps): JSX.Element {
    if (props.metricsPeriods.length === 0) {
        return <div />;
    }

    if (props.metricsPeriods[3].endDate.valueOf() === PERIOD_END_DATE_UNDEFINED.valueOf()) {
        return <div />;
    }

    return (
        <div className="metrics-data-table" data-testid="metricsDataTableHeader">
            <div id="periodRange-label" className="thick-border-top data-label bottom-row">
                Date Range
            </div>
            <div data-testid="periodRange-1" id="periodRange-0" className="thick-border-top data-label bottom-row">
                {formatPeriodRange(props.metricsPeriods[0])}
            </div>
            <div data-testid="periodRange-2" id="periodRange-1" className="thick-border-top data-label bottom-row">
                {formatPeriodRange(props.metricsPeriods[1])}
            </div>
            <div data-testid="periodRange-3" id="periodRange-2" className="thick-border-top data-label bottom-row">
                {formatPeriodRange(props.metricsPeriods[2])}
            </div>
            <div
                data-testid="periodRange-3"
                id="periodRange-3"
                className="thick-border-top data-label bottom-row last-col"
            >
                {formatPeriodSummary(props.metricsPeriods)}
            </div>
        </div>
    );
}

export class MetricsDataTableHeaderProps {
    constructor(metricsPeriods: Period[] = []) {
        this.metricsPeriods = metricsPeriods;
    }
    metricsPeriods: Period[];
}

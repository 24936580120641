import React from "react";
import MetricsDataTableFlow from "./MetricsDataTableFlow";
import MetricsDataTableBacklog from "./MetricsDataTableBacklog";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import { VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import MetricsDataTableVelocity from "./MetricsDataTableVelocity";
import { Period } from "rally_core/build";

function MetricsDataTableContainerFlow(props: MetricsDataTableContainerFlowProps): JSX.Element {
    return (
        <div data-testid="metricsDataTable">
            <MetricsDataTableVelocity velocityMetrics={props.velocityMetrics} />
            <MetricsDataTableFlow flowMetrics={props.flowMetrics} />
            <MetricsDataTableBacklog backlogHealthMetrics={props.backlogHealthMetrics} />
        </div>
    );
}

export default MetricsDataTableContainerFlow;

export interface MetricsDataTableContainerFlowProps {
    periodLength: number;
    velocityMetrics: VelocityMetrics[];
    flowMetrics: FlowMetrics[];
    backlogHealthMetrics: BacklogHealthMetrics[];
    selectedPlanningStyle: string;
    projectId: number;
    metricsPeriods: Period[];
}

export class ChartGenerator {
    private static instance: ChartGenerator;

    private gaugeChartWriter: GaugeChartWriter;

    private constructor(gaugeChartWriter: GaugeChartWriter) {
        this.gaugeChartWriter = gaugeChartWriter;
    }

    static getInstance(): ChartGenerator {
        function defaultGaugeChartWriter() {
            return {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                write: function (props: GaugeChartWriterProps): void {
                    //do nothing
                },
            } as GaugeChartWriter;
        }

        if (!ChartGenerator.instance) {
            ChartGenerator.instance = new ChartGenerator(defaultGaugeChartWriter());
        }

        return ChartGenerator.instance;
    }

    static configureGaugeChartWriter(writer: GaugeChartWriter): void {
        ChartGenerator.getInstance().gaugeChartWriter = writer;
    }

    writeGaugeChart(props: GaugeChartWriterProps): void {
        this.gaugeChartWriter.write(props);
    }
}

export interface GaugeChartWriter {
    write(props: GaugeChartWriterProps): void;
}

export interface GaugeChartWriterProps {
    id: string;
    name: string;
    metric: number | undefined;
}

import React, { ReactFragment, useState } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";

import "./PeriodSelector.css";
import "flatpickr/dist/themes/airbnb.css";
import { Iteration } from "rally_core/build";
import { RenderLogger } from "./utils/RenderLogger";
import { PlanningStyle } from "./PlanningStyle";
import { PERIOD_END_DATE_UNDEFINED, PERIOD_LENGTH_UNDEFINED, SELECTED_ITERATION_UNDEFINED } from "./AppProps";

const renderLogger = new RenderLogger("PeriodSelector");

function PeriodSelectorJSX(props: PeriodSelectorProps): JSX.Element {
    renderLogger.log(
        JSON.stringify(
            {
                selectedPlanningStyle: props.selectedPlanningStyle,
                selectedIterationId: props.selectedIterationId,
                periodEndDate: props.periodEndDate,
                periodLength: props.periodLength,
                iterationsLength: props.iterations.length,
            },
            null,
            2
        )
    );

    /****************************************
     * WORKING VARIABLES
     ****************************************/
    const [formState, setFormState] = useState({
        selectedPlanningStyle: props.selectedPlanningStyle,
        selectedIterationId: props.selectedIterationId,
        periodEndDate: props.periodEndDate,
        periodLength: props.periodLength,
    });

    function selectionChanged(
        selectedPlanningStyle: PlanningStyle,
        selectedIterationId: number,
        periodEndDate: Date,
        periodLength: number
    ) {
        props.selectionChanged(selectedPlanningStyle, selectedIterationId, periodEndDate, periodLength);
    }

    /****************************************
     * DEFINE FUNCTIONS
     ****************************************/
    function changePlanningStyle(event: React.ChangeEvent<HTMLSelectElement>) {
        let newStyle = event.target.value;

        function planningStyleHasChanged() {
            return newStyle !== props.selectedPlanningStyle;
        }

        function validateStyleSelected(style: string) {
            return style === "Scrum" || style === "Flow";
        }

        if (planningStyleHasChanged() && !validateStyleSelected(newStyle)) {
            newStyle = "none";
        }

        if (planningStyleHasChanged()) {
            if (newStyle === PlanningStyle.SCRUM) {
                const selectedIterationId = defaultIterationId(props.iterations);
                setFormState({
                    selectedPlanningStyle: newStyle,
                    selectedIterationId: selectedIterationId,
                    periodEndDate: PERIOD_END_DATE_UNDEFINED,
                    periodLength: PERIOD_LENGTH_UNDEFINED,
                });
                selectionChanged(newStyle, selectedIterationId, PERIOD_END_DATE_UNDEFINED, PERIOD_LENGTH_UNDEFINED);
            } else if (newStyle === PlanningStyle.FLOW) {
                setFormState({
                    selectedPlanningStyle: newStyle,
                    selectedIterationId: SELECTED_ITERATION_UNDEFINED,
                    periodEndDate: defaultPeriodEndDate(),
                    periodLength: DEFAULT_PERIOD_LENGTH,
                });
                selectionChanged(newStyle, SELECTED_ITERATION_UNDEFINED, defaultPeriodEndDate(), DEFAULT_PERIOD_LENGTH);
            }
        }
    }

    function changeIteration(iterationId: number) {
        setFormState({
            selectedPlanningStyle: PlanningStyle.SCRUM,
            selectedIterationId: iterationId,
            periodEndDate: PERIOD_END_DATE_UNDEFINED,
            periodLength: PERIOD_LENGTH_UNDEFINED,
        });
        selectionChanged(PlanningStyle.SCRUM, iterationId, PERIOD_END_DATE_UNDEFINED, PERIOD_LENGTH_UNDEFINED);
    }

    function iterationSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        let iterationId = Number(event.target.value);

        if (isNaN(iterationId)) {
            iterationId = defaultIterationId(props.iterations);
        }
        changeIteration(iterationId);
    }

    function iterationSelector(): ReactFragment {
        function addOptions() {
            return props.iterations.map((iteration) => (
                <option key={iteration.objectId} value={iteration.objectId}>
                    {iteration.name}
                </option>
            ));
        }
        if (formState.selectedPlanningStyle === "Scrum") {
            return (
                <React.Fragment>
                    <label className="periodSelectorPaddingLeft">Iteration:</label>
                    <select
                        className="periodSelectorPaddingLeft"
                        data-testid="selectIteration"
                        name="selectIteration"
                        id="selectIteration"
                        value={formState.selectedIterationId}
                        onChange={(event) => iterationSelected(event)}
                    >
                        {addOptions()}
                    </select>
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }

    function changePeriodEndDate(date: Date[]) {
        setFormState({
            selectedPlanningStyle: PlanningStyle.FLOW,
            selectedIterationId: SELECTED_ITERATION_UNDEFINED,
            periodEndDate: date[0],
            periodLength: formState.periodLength,
        });
    }

    function changePeriodLength(event: React.ChangeEvent<HTMLInputElement>) {
        let length = Number(event.target.value);

        if (isNaN(length) || length < 1) {
            length = DEFAULT_PERIOD_LENGTH;
        }

        setFormState({
            selectedPlanningStyle: PlanningStyle.FLOW,
            selectedIterationId: SELECTED_ITERATION_UNDEFINED,
            periodEndDate: formState.periodEndDate,
            periodLength: length,
        });
    }

    function goButtonPressed() {
        selectionChanged(
            PlanningStyle.FLOW,
            SELECTED_ITERATION_UNDEFINED,
            formState.periodEndDate,
            formState.periodLength
        );
    }

    /****************************************
     * DISPLAY LOGIC
     ****************************************/
    function periodEntry() {
        function dateFormatConvertedToFlatPickr(): string {
            let dateFormat = moment().localeData().longDateFormat("l");
            dateFormat = dateFormat.replace("D", "j");
            dateFormat = dateFormat.replace("M", "n");
            dateFormat = dateFormat.replace("YYYY", "Y");
            return dateFormat;
        }

        if (formState.selectedPlanningStyle === "Flow") {
            return (
                <React.Fragment>
                    <label className="periodSelectorPaddingLeft">Period End Date:</label>
                    <Flatpickr
                        className="periodSelectorPaddingLeft"
                        id="periodEndDate"
                        data-testid="periodEndDate"
                        options={{ dateFormat: dateFormatConvertedToFlatPickr() }}
                        value={formState.periodEndDate}
                        onChange={(date) => {
                            changePeriodEndDate(date);
                        }}
                    />

                    <label className="periodSelectorPaddingLeft">Period Length:</label>
                    <input
                        className="periodSelectorPaddingLeft"
                        id="periodLength"
                        data-testid="periodLength"
                        type="text"
                        value={formState.periodLength}
                        onChange={(event) => changePeriodLength(event)}
                    />

                    <input
                        className="periodSelectorPaddingLeft"
                        id="periodGo"
                        data-testid="periodGo"
                        type="button"
                        value="Go"
                        onClick={() => goButtonPressed()}
                    />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
    return (
        <div id="PeriodSelector">
            <form>
                <label>Planning Style:</label>
                <select
                    className="periodSelectorPaddingLeft"
                    data-testid="selectPlanningStyle"
                    value={formState.selectedPlanningStyle}
                    onChange={(event) => changePlanningStyle(event)}
                >
                    <option data-testid="planningStyleNone" disabled value="none">
                        Select Delivery Style
                    </option>
                    <option data-testid="planningStyleScrum" value="Scrum">
                        Scrum
                    </option>
                    <option data-testid="planningStyleFlow" value="Flow">
                        Flow
                    </option>
                </select>
                {iterationSelector()}
                {periodEntry()}
            </form>
        </div>
    );
}

export function defaultIterationId(iterations: Iteration[]): number {
    const now = moment().toDate();
    for (let i = 0; i < iterations.length; i++) {
        if (iterations[i].startDate.valueOf() <= now.valueOf() && now.valueOf() < iterations[i].endDate.valueOf()) {
            return iterations[i].objectId;
        }
    }

    return -1;
}

function defaultPeriodEndDate() {
    return moment().day("Friday").endOf("day").toDate();
}

const DEFAULT_PERIOD_LENGTH = 1;

export class PeriodSelectorProps {
    selectedPlanningStyle: string;
    selectedIterationId: number;
    periodEndDate: Date;
    periodLength: number;
    iterations: Iteration[];
    selectionChanged: (
        selectedPlanningStyle: string,
        selectedIterationId: number,
        periodEndDate: Date,
        periodLength: number
    ) => void;

    constructor(
        selectedPlanningStyle = "none",
        selectedIterationId = -1,
        periodEndDate: Date = moment().endOf("day").toDate(),
        periodLength = 1,
        iterations: Iteration[] = [],
        selectionChanged: (
            selectedPlanningStyle: string,
            selectedIterationId: number,
            periodEndDate: Date,
            periodLength: number
        ) => void = () => {
            return;
        }
    ) {
        this.selectedPlanningStyle = selectedPlanningStyle;
        this.selectedIterationId = selectedIterationId;
        this.periodEndDate = periodEndDate;
        this.periodLength = periodLength;
        this.iterations = iterations;
        this.selectionChanged = selectionChanged;
    }
}

const PeriodSelector = React.memo(PeriodSelectorJSX);

export default PeriodSelector;

import { GaugeChartWriter, GaugeChartWriterProps } from "./ChartGenerator";
import { formatDecimalPlaces } from "../utils/StringFunctions";
import c3 from "c3";

export default class C3GaugeChartWriter implements GaugeChartWriter {
    write(props: GaugeChartWriterProps): void {
        if (!props.metric) {
            const element = document.getElementById(props.id);
            if (element) {
                element.innerHTML = "--";
            }
            return;
        }

        const maxValue = props.metric * 100 > 100 ? props.metric * 100 : 100;

        const chart = c3.generate({
            bindto: `#${props.id}`,
            data: {
                columns: [[props.name, 0]],
                type: "gauge",
            },
            gauge: {
                label: {
                    format: function (value: number) {
                        return formatDecimalPlaces(value, 1);
                    },
                    show: false, // to turn off the min/max labels.
                },
                min: 0,
                max: maxValue,
            },
            transition: {
                duration: 2500,
            },
            legend: {
                hide: true,
            },
            color: {
                pattern: ["#FF0000", "#F97600", "#F6C600", "#60B044"], // the three color levels for the percentage values.
                threshold: {
                    values: [25, 50, 80, 100],
                },
            },
            size: {
                height: 120,
            },
            padding: {
                top: 5,
                right: 0,
                bottom: 0,
                left: 0,
            },
        });

        //animate gauge
        chart.load({
            columns: [[props.name, props.metric * 100]],
        });
    }
}

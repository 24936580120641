import React from "react";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { formatDecimalPlaces } from "../utils/StringFunctions";
import { isEqual } from "lodash";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "../tippy.css";

function flowMetricValue(fmv: FlowMetrics, func: (fmv: FlowMetrics) => number): string {
    if (!fmv || isEqual({}, fmv)) {
        return "--";
    }

    return formatDecimalPlaces(func(fmv), 1);
}

export default function MetricsDataTableFlow(props: MetricsDataTableFlowProps): JSX.Element {
    return (
        <div className="metrics-data-table">
            <div className="iterationmetrics-sectionheader">Flow Metrics</div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            For stories accepted this period , the average time it takes (in days) for a user story to
                            be accepted after it has been entered into backlog.{" "}
                            <span className="tooltip-highlight">Lead time is a measurement of responsiveness</span>
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Lead Time (days)</span>
                </Tippy>
            </div>
            <div id="leadTime-0" data-testid="leadTime-0" className="data-numeric">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.averageLeadTime)}
            </div>
            <div id="leadTime-1" data-testid="leadTime-1" className="data-numeric">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.averageLeadTime)}
            </div>
            <div id="leadTime-2" data-testid="leadTime-2" className="data-numeric">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.averageLeadTime)}
            </div>
            <div id="leadTime-3" data-testid="leadTime-3" className="data-numeric">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.averageLeadTime)}
            </div>
            <div className="row-label data-tooltip">
                <Tippy
                    content={<span>75% of All user stories completed with a lead time at or below this figure</span>}
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Lead Time 75% Confidence Interval</span>
                </Tippy>
            </div>
            <div id="leadTime75-0" data-testid="leadTime75-0" className="data-numeric">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.leadTime75)}
            </div>
            <div id="leadTime75-1" data-testid="leadTime75-1" className="data-numeric">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.leadTime75)}
            </div>
            <div id="leadTime75-2" data-testid="leadTime75-2" className="data-numeric">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.leadTime75)}
            </div>
            <div id="leadTime75-3" data-testid="leadTime75-3" className="data-numeric">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.leadTime75)}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={<span>90% of All user stories completed with a lead time at or below this figure</span>}
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Lead Time 90% Confidence Interval</span>
                </Tippy>
            </div>
            <div id="leadTime90-0" data-testid="leadTime90-0" className="data-numeric">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.leadTime90)}
            </div>
            <div id="leadTime90-1" data-testid="leadTime90-1" className="data-numeric">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.leadTime90)}
            </div>
            <div id="leadTime90-2" data-testid="leadTime90-2" className="data-numeric">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.leadTime90)}
            </div>
            <div id="leadTime90-3" data-testid="leadTime90-3" className="data-numeric">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.leadTime90)}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            For stories accepted this iteration, the average time it takes (in days) for a user story to
                            be accepted after it has been started. Cycle time measures both the time the story is being
                            actively worked on and the time that is spent blocked or in-queue.
                            <span className="tooltip-highlight">
                                Important Metric - improving this metric will generally improve all other metrics
                            </span>
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Cycle Time (days)</span>
                </Tippy>
            </div>
            <div id="cycleTime-0" data-testid="cycleTime-0" className="data-numeric">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.averageCycleTime)}
            </div>
            <div id="cycleTime-1" data-testid="cycleTime-1" className="data-numeric">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.averageCycleTime)}
            </div>
            <div id="cycleTime-2" data-testid="cycleTime-2" className="data-numeric">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.averageCycleTime)}
            </div>
            <div id="cycleTime-3" data-testid="cycleTime-3" className="data-numeric">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.averageCycleTime)}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={<span>75% of All user stories completed with a cycle time at or below this figure</span>}
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Cycle Time 75% Confidence Interval</span>
                </Tippy>
            </div>
            <div id="cycleTime75-0" data-testid="cycleTime75-0" className="data-numeric">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.cycleTime75)}
            </div>
            <div id="cycleTime75-1" data-testid="cycleTime75-1" className="data-numeric">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.cycleTime75)}
            </div>
            <div id="cycleTime75-2" data-testid="cycleTime75-2" className="data-numeric">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.cycleTime75)}
            </div>
            <div id="cycleTime75-3" data-testid="cycleTime75-3" className="data-numeric">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.cycleTime75)}
            </div>

            <div className="row-label bottom-row data-tooltip">
                <Tippy
                    content={<span>90% of All user stories completed with a cycle time at or below this figure</span>}
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Cycle Time 90% Confidence Interval</span>
                </Tippy>
            </div>
            <div id="cycleTime90-0" data-testid="cycleTime90-0" className="data-numeric bottom-row">
                {flowMetricValue(props.flowMetrics[0], (fmv) => fmv.cycleTime90)}
            </div>
            <div id="cycleTime90-1" data-testid="cycleTime90-1" className="data-numeric bottom-row">
                {flowMetricValue(props.flowMetrics[1], (fmv) => fmv.cycleTime90)}
            </div>
            <div id="cycleTime90-2" data-testid="cycleTime90-2" className="data-numeric bottom-row">
                {flowMetricValue(props.flowMetrics[2], (fmv) => fmv.cycleTime90)}
            </div>
            <div id="cycleTime90-3" data-testid="cycleTime90-3" className="data-numeric bottom-row">
                {flowMetricValue(props.flowMetrics[3], (fmv) => fmv.cycleTime90)}
            </div>
        </div>
    );
}

export interface MetricsDataTableFlowProps {
    flowMetrics: FlowMetrics[];
}

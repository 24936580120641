import { Iteration, Period } from "rally_core/build";
import { cloneDeep } from "lodash";
import moment from "moment";

export function determineIterationsForMetrics(iterationSelectedObjectId: number, iterations: Iteration[]): Period[] {
    const iterationsSorted = sortIterationsInAscendingOrder(iterations);

    const index = findSelectedIterationIndex(iterationSelectedObjectId, iterationsSorted);

    const startDate = index >= 2 ? iterationsSorted[index - 2].startDate : iterationsSorted[0].startDate;

    const empty: Iteration = {} as Iteration;

    return [
        index >= 2 ? iterationsSorted[index - 2] : empty,
        index >= 1 ? iterationsSorted[index - 1] : empty,
        iterationsSorted[index],
        new Iteration(-1, startDate, iterationsSorted[index].endDate, "Summary", "", iterations[0].teamId),
    ];
}

function sortIterationsInAscendingOrder(iterations: Iteration[]): Iteration[] {
    const iteartionsClone = cloneDeep(iterations);
    return iteartionsClone.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());
}

function findSelectedIterationIndex(iterationSelectedObjectId: number, iterations: Iteration[]): number {
    for (let i = 0; i < iterations.length; i++) {
        if (iterationSelectedObjectId === iterations[i].objectId) {
            return i;
        }
    }

    return -1;
}

export function determineVariationIterations(iterationSelectedObjectId: number, iterations: Iteration[]): Period[][] {
    const iterationsSorted = sortIterationsInAscendingOrder(iterations);
    const index = findSelectedIterationIndex(iterationSelectedObjectId, iterationsSorted);

    return [
        theIterationPlusThePreviousTwo(index - 2, iterationsSorted),
        theIterationPlusThePreviousTwo(index - 1, iterationsSorted),
        theIterationPlusThePreviousTwo(index, iterationsSorted),
        [],
    ];
}

function theIterationPlusThePreviousTwo(index: number, iterationsSorted: Iteration[]): Period[] {
    if (index < 0) {
        return [];
    }

    const ret = [iterationsSorted[index]];

    for (let i = 1; i <= 2; i++) {
        if (index - i >= 0) {
            ret.push(iterationsSorted[index - i]);
        }
    }

    return sortIterationsInAscendingOrder(ret) as Period[];
}

export function determinePeriodsForMetrics(period: Period): Period[] {
    const duration = calculatePeriodDuration(period);

    return [
        Period.fromStartDate(
            moment(period.startDate)
                .subtract(duration * 2, "weeks")
                .toDate(),
            duration
        ),
        Period.fromStartDate(moment(period.startDate).subtract(duration, "weeks").toDate(), duration),
        period,
        Period.fromEndDate(period.endDate, 3 * duration),
    ];
}

export function determineVariationPeriods(periods: Period[]): Period[][] {
    if (!periods || periods.length !== 4) {
        return [[], [], [], []];
    }

    return [
        calculateVariationPeriodsForPeriod(periods[0]),
        calculateVariationPeriodsForPeriod(periods[1]),
        calculateVariationPeriodsForPeriod(periods[2]),
        [],
    ];
}

function calculateVariationPeriodsForPeriod(period: Period): Period[] {
    const duration = calculatePeriodDuration(period);

    return [
        Period.fromStartDate(
            moment(period.startDate)
                .subtract(duration * 2, "weeks")
                .toDate(),
            duration
        ),
        Period.fromStartDate(
            moment(period.startDate)
                .subtract(duration * 1, "weeks")
                .toDate(),
            duration
        ),
        period,
    ];
}

function calculatePeriodDuration(period: Period): number {
    return Math.round(moment(period.endDate).diff(moment(period.startDate), "weeks", true));
}

export function determinePeriodName(period: Period): string {
    if (isAnIteration(period)) {
        return (period as Iteration).name;
    } else {
        return moment(period.startDate).format("l") + "-" + moment(period.endDate).format("l");
    }
}

export function isAnIteration(period: Period): boolean {
    return period instanceof Iteration;
}

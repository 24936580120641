import React, { useEffect, useState } from "react";
import { RenderLogger } from "./utils/RenderLogger";
import "./DataRetrievalDisplay.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "./tippy.css";
import { DataRetrievalEventManager, DataRetrievalStatus } from "./utils/DataRetrievalEvent";

const RETRIEVING_DATA = "Retrieving Data...";
const RETRIEVING_DATA_ERROR = "Error Retrieving Data";

const renderLogger = new RenderLogger("DataRetrievalDisplay");

export function determineMessageToDisplay(): string {
    const dataRetrievalEvents = DataRetrievalEventManager.getInstance().dataRetrievalEvents;
    let message = "";

    for (let i = 0; i < dataRetrievalEvents.length; i++) {
        if (dataRetrievalEvents[i].dataRetrievalStatus === DataRetrievalStatus.ERROR) {
            message = RETRIEVING_DATA_ERROR;
            break;
        } else if (dataRetrievalEvents[i].dataRetrievalStatus === DataRetrievalStatus.STARTED) {
            message = RETRIEVING_DATA;
        }
    }

    return message;
}

export default function DataRetrievalDisplay(): JSX.Element {
    renderLogger.log(JSON.stringify(DataRetrievalEventManager.getInstance().dataRetrievalEvents, null, 2));

    const [updateCount, setUpdateCount] = useState(0);
    const [message, setMessage] = useState(determineMessageToDisplay());

    //Setup subscription to data retrieval events
    useEffect(() => {
        let isMounted = true;

        DataRetrievalEventManager.getInstance().addSubscriber(() => {
            if (isMounted) {
                setUpdateCount(updateCount + 1);
                setMessage(determineMessageToDisplay());
            }
        });

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, []);

    function determineStyleClass(message: string) {
        if (message === RETRIEVING_DATA_ERROR) {
            return "dataRetrievalError";
        }
        return "dataRetrieval";
    }

    function translateStatus(dataRetrievalStatus: DataRetrievalStatus) {
        switch (dataRetrievalStatus) {
            case DataRetrievalStatus.STARTED: {
                return "Started";
            }
            case DataRetrievalStatus.ERROR: {
                return "Error";
            }
            default: {
                return "Completed";
            }
        }
    }

    function demonstrateInfoIfNecessary(message: string) {
        if (message === "") {
            return "";
        } else {
            const dataRows = DataRetrievalEventManager.getInstance().dataRetrievalEvents.map((dataRetrievalEvent) => (
                <tr key={dataRetrievalEvent.id}>
                    <td className={"dataRetrievalEvents"}>{dataRetrievalEvent.description}</td>
                    <td className={"dataRetrievalEvents"}>{translateStatus(dataRetrievalEvent.dataRetrievalStatus)}</td>
                </tr>
            ));

            return (
                <React.Fragment>
                    <Tippy
                        content={
                            <table className={"dataRetrievalEvents"}>
                                <thead>
                                    <tr>
                                        <th className={"dataRetrievalEvents"}>Event</th>
                                        <th className={"dataRetrievalEvents"}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>{dataRows}</tbody>
                            </table>
                        }
                        placement="bottom-start"
                        arrow={true}
                        className="tippy-tooltip"
                        maxWidth="none"
                    >
                        <svg
                            className="bi bi-info-circle"
                            width="1.8rem"
                            height="1.8rem"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                            <circle cx="8" cy="4.5" r="1" />
                        </svg>
                    </Tippy>
                </React.Fragment>
            );
        }
    }
    return (
        <div data-testid="dataRetrievalStatus" className={determineStyleClass(message)}>
            {message}
            {demonstrateInfoIfNecessary(message)}
        </div>
    );
}

import React, { useMemo, useState } from "react";
import "./App.css";
import PeriodSelector from "./PeriodSelector";
import MetricsDataTableContainer from "./metrics/MetricsDataTableContainer";
import { RenderLogger } from "./utils/RenderLogger";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import MetricsBanner from "./metrics/MetricsBanner";
import DataRetrievalDisplay from "./DataRetrievalDisplay";
import Footer from "./Footer";
import { MetricsDataTableHeader } from "./metrics/MetricsDataTableHeader";
import { useAppDataRetrieval } from "./hooks/useAppDataRetrieval";
import { AppProps } from "./AppProps";
import { PlanningStyle } from "./PlanningStyle";
import { DataRetrievalEventManager } from "./utils/DataRetrievalEvent";
const renderLogger = new RenderLogger("App");
export default function App(appProps: AppProps): JSX.Element {
    /****************************************
     * STATE AND VARIABLE DEFINITION
     ****************************************/

    const [selectedPlanningStyle, setSelectedPlanningStyle] = useState(appProps.selectedPlanningStyle);
    const [selectedIterationId, setSelectedIterationId] = useState(appProps.selectedIterationId);
    const [periodEndDate, setPeriodEndDate] = useState(appProps.periodEndDate);
    const [periodLength, setPeriodLength] = useState(appProps.periodLength);

    const [iterations, setIterations] = useState(appProps.iterations);

    const [metricsPeriods, setMetricsPeriods] = useState(appProps.metricsPeriods);
    const [previousMetricsPeriods, setPreviousMetricsPeriods] = useState(appProps.previousMetricsPeriods);

    const [userStories, setUserStories] = useState(appProps.userStories);
    const [readyUserStories, setReadyUserStories] = useState(appProps.readyUserStories);
    const [committedUserStories, setCommittedUserStories] = useState(appProps.committedUserStories);

    const [scrumMetrics, setScrumMetrics] = useState(appProps.scrumMetrics);
    const [velocityMetrics, setVelocityMetrics] = useState(appProps.velocityMetrics);
    const [flowMetrics, setFlowMetrics] = useState(appProps.flowMetrics);
    const [backlogHealthMetrics, setBacklogHealthMetrics] = useState(appProps.backlogHealthMetrics);
    //  eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [generationTs, setGenerationTs] = useState(appProps.generationTs);

    function modifyAppPropsToIncludeSetStateFunctions(): AppProps {
        return {
            rallyEnvironmentParser: appProps.rallyEnvironmentParser,
            retrieveIterations: appProps.retrieveIterations,
            retrieveUserStories: appProps.retrieveUserStories,
            retrieveReadyUserStories: appProps.retrieveReadyUserStories,
            retrieveCommittedUserStories: appProps.retrieveCommittedUserStories,

            selectedPlanningStyle: selectedPlanningStyle,
            iterations: iterations,
            selectedIterationId: selectedIterationId,
            periodEndDate: periodEndDate,
            periodLength: periodLength,
            metricsPeriods: metricsPeriods,
            previousMetricsPeriods: previousMetricsPeriods,
            userStories: userStories,
            readyUserStories: readyUserStories,
            committedUserStories: committedUserStories,
            scrumMetrics: scrumMetrics,
            velocityMetrics: velocityMetrics,
            flowMetrics: flowMetrics,
            backlogHealthMetrics: backlogHealthMetrics,

            setSelectedPlanningStyle: setSelectedPlanningStyle,
            setIterations: setIterations,
            setSelectedIterationId: setSelectedIterationId,
            setPeriodEndDate: setPeriodEndDate,
            setPeriodLength: setPeriodLength,
            setMetricsPeriods: setMetricsPeriods,
            setPreviousMetricsPeriods: setPreviousMetricsPeriods,
            setUserStories: setUserStories,
            setReadyUserStories: setReadyUserStories,
            setCommittedUserStories: setCommittedUserStories,
            setScrumMetrics: setScrumMetrics,
            setVelocityMetrics: setVelocityMetrics,
            setFlowMetrics: setFlowMetrics,
            setBacklogHealthMetrics: setBacklogHealthMetrics,
            setGenerationTs: setGenerationTs,
        } as AppProps;
    }

    const props = modifyAppPropsToIncludeSetStateFunctions();

    /****************************************
     * FUNCTION DEFINITION
     ****************************************/

    function generateDebugInfo() {
        return JSON.stringify(props, null, 2);
    }

    function clearMetricsData(): void {
        setUserStories([]);
        setReadyUserStories([]);
        setCommittedUserStories([]);
        setScrumMetrics([]);
        setFlowMetrics([]);
        setBacklogHealthMetrics([]);
        if (props.selectedPlanningStyle !== PlanningStyle.NONE) {
            DataRetrievalEventManager.getInstance().clearEvents();
        }
    }

    function selectionChanged(
        selectedPlanningStyle: string,
        selectedIterationId: number,
        periodEndDate: Date,
        periodLength: number
    ) {
        setMetricsPeriods([]);
        setPreviousMetricsPeriods([]);

        setSelectedPlanningStyle(selectedPlanningStyle as PlanningStyle);
        setSelectedIterationId(selectedIterationId);
        setPeriodEndDate(periodEndDate);
        setPeriodLength(periodLength);

        clearMetricsData();
    }

    function metricsCalculated(
        scrumMetrics: ScrumMetrics[],
        velocityMetrics: VelocityMetrics[],
        flowMetrics: FlowMetrics[],
        backlogHealthMetrics: BacklogHealthMetrics[]
    ): void {
        setScrumMetrics(scrumMetrics);
        setVelocityMetrics(velocityMetrics);
        setFlowMetrics(flowMetrics);
        setBacklogHealthMetrics(backlogHealthMetrics);
    }

    /****************************************
     * USE HOOKS
     ****************************************/
    renderLogger.log(generateDebugInfo());

    useAppDataRetrieval(props);

    /****************************************
     * DISPLAY LOGIC
     ****************************************/
    return (
        <div className="App">
            <div className="pdo-metrics">
                {/* SELECT PLANNING STYLE */}
                <div className="empty" />
                <div id={"topBar"}>
                    <div id={"topBarLeft"}>
                        <PeriodSelector
                            iterations={props.iterations}
                            selectedPlanningStyle={props.selectedPlanningStyle}
                            selectedIterationId={props.selectedIterationId}
                            periodEndDate={props.periodEndDate}
                            periodLength={props.periodLength}
                            selectionChanged={selectionChanged}
                        />
                    </div>
                    <div id={"topBarRight"}>
                        <DataRetrievalDisplay />
                    </div>
                </div>
                <div className="empty" />
                {/* METRICS GAUGES */}
                <div className="empty" />
                <MetricsBanner
                    selectedPlanningStyle={props.selectedPlanningStyle}
                    scrumMetrics={props.scrumMetrics}
                    velocityMetrics={props.velocityMetrics}
                    flowMetrics={props.flowMetrics}
                />
                <div className="empty" />
                {/* METRICS DATA TABLE HEADER */}
                <div className="empty" />
                <MetricsDataTableHeader metricsPeriods={props.metricsPeriods} />
                <div className="empty" />
                {/* METRICS DATA */}
                <div className="empty" />
                <MetricsDataTableContainer
                    periodLength={props.periodLength}
                    selectedPlanningStyle={props.selectedPlanningStyle}
                    projectId={props.rallyEnvironmentParser.projectId}
                    iterations={props.iterations}
                    metricsPeriods={props.metricsPeriods}
                    previousMetricsPeriods={props.previousMetricsPeriods}
                    userStories={props.userStories}
                    readyUserStories={props.readyUserStories}
                    committedUserStories={props.committedUserStories}
                    metricsCalculated={useMemo(() => metricsCalculated, [])}
                />
                <div className="empty" />
                {/* METRICS DATA */}
                <div className="empty" />
                <div className="empty" />
                <div className="empty" />
                {/* FOOTER */}
                <div className="empty" />
                <Footer {...props} />
                <div className="empty" />
            </div>
        </div>
    );
}

import { AppProps, PERIOD_END_DATE_UNDEFINED } from "../AppProps";
import { AsyncDataRetrievalProps, useAsyncDataRetrieval } from "./useAsyncDataRetrieval";
import { useMemo } from "react";
import { Iteration, Period, RallyEnvironmentParser, UserStory } from "rally_core/build";
import { DataRetrievalEventManager, INTENTIONAL_UNIT_TEST_ERROR } from "../utils/DataRetrievalEvent";
import {
    determineIterationsForMetrics,
    determinePeriodsForMetrics,
    determineVariationIterations,
    determineVariationPeriods,
} from "../utils/PeriodUtils";
import moment from "moment";
import { RenderLogger } from "../utils/RenderLogger";
import { isEqual } from "lodash";
import { PlanningStyle } from "../PlanningStyle";

const renderLogger = new RenderLogger("useAppDataRetrieval");

export function useAppDataRetrieval(props: AppProps): AppProps {
    /****************************************
     * HOOKS DEFINITION
     ****************************************/
    function defaultOnError(error: Error) {
        if (error.message !== INTENTIONAL_UNIT_TEST_ERROR) {
            console.trace(error);
        }
    }

    /**
     * Dependency : [] - only needs to be run once
     */
    function useIterationRetriever() {
        const asyncFunction = () => {
            return props.retrieveIterations(props.rallyEnvironmentParser);
        };

        const asyncProps = new AsyncDataRetrievalProps();
        asyncProps.setGenerationTs = props.setGenerationTs;
        asyncProps.asyncDataRetrievalFunction = asyncFunction;
        asyncProps.onSuccess = (iterations: Iteration[] | UserStory[] | UserStory[][]) => {
            props.setIterations(iterations as Iteration[]);
        };
        asyncProps.onError = defaultOnError;
        asyncProps.description = "Iterations";
        asyncProps.recordDataRetrievalEvent = true;

        useAsyncDataRetrieval(
            //useMemo prevents massive overrendering
            // eslint-disable-next-line
            useMemo(() => asyncProps, [])
        );
    }

    /**
     * Dependency : Criteria used for defining Periods
     */
    function usePeriodsForMetricsCalculator(): void {
        const asyncFunction = () => Promise.resolve([] as Iteration[]);

        const onSuccess = () => {
            if (props.selectedPlanningStyle === PlanningStyle.SCRUM && props.selectedIterationId > -1) {
                const metricsPeriods = determineIterationsForMetrics(props.selectedIterationId, props.iterations);
                const previousMetricsPeriods = determineVariationIterations(
                    props.selectedIterationId,
                    props.iterations
                );
                props.setMetricsPeriods(metricsPeriods);
                props.setPreviousMetricsPeriods(previousMetricsPeriods);
            } else if (
                props.selectedPlanningStyle === "Flow" &&
                props.periodEndDate.valueOf() > PERIOD_END_DATE_UNDEFINED.valueOf() &&
                props.periodLength > 0
            ) {
                const metricsPeriods = determinePeriodsForMetrics(
                    Period.fromEndDate(props.periodEndDate, props.periodLength)
                );
                const previousMetricsPeriods = determineVariationPeriods(metricsPeriods);
                props.setMetricsPeriods(metricsPeriods);
                props.setPreviousMetricsPeriods(previousMetricsPeriods);
            }
        };

        const asyncProps = new AsyncDataRetrievalProps();
        asyncProps.setGenerationTs = props.setGenerationTs;
        asyncProps.asyncDataRetrievalFunction = asyncFunction;
        asyncProps.onSuccess = onSuccess;
        asyncProps.onError = defaultOnError;
        asyncProps.description = "usePeriodsForMetricsCalculator";

        useAsyncDataRetrieval(
            useMemo(
                () => asyncProps,
                [props.selectedPlanningStyle, props.selectedIterationId, props.periodEndDate, props.periodLength]
            )
        );
    }

    function readyToRetrieveUserStories() {
        function metricsPeriodsDefined() {
            return props.metricsPeriods && props.metricsPeriods.length > 0;
        }

        function previouMetricsPeriodsDefined() {
            return props.previousMetricsPeriods && props.previousMetricsPeriods.length > 0;
        }

        function atLeastOneValidPeriod() {
            for (let i = 0; i < props.metricsPeriods.length; i++) {
                if (!isEqual(props.metricsPeriods[i], {})) {
                    return true;
                }
            }
            return false;
        }

        function atLeastOneValidMetricPeriod() {
            for (let i = 0; i < props.previousMetricsPeriods.length; i++) {
                if (props.previousMetricsPeriods[i].length > 0 && !isEqual(props.previousMetricsPeriods[i][0], {})) {
                    return true;
                }
            }

            return false;
        }

        return (
            metricsPeriodsDefined() &&
            previouMetricsPeriodsDefined() &&
            atLeastOneValidPeriod() &&
            atLeastOneValidMetricPeriod()
        );
    }

    /**
     * Dependency : Previous Metrics Periods.  Retreive user stories for
     * current periods and those used for calculating variation
     */
    function useUserStoryRetriever() {
        function getEarliestDate(variationPeriods: Period[][], summaryPeriodBeginDate: Date): Date {
            for (let i = 0; i < variationPeriods.length; i++) {
                if (variationPeriods[i].length > 0) {
                    return variationPeriods[i][0].startDate;
                }
            }
            return summaryPeriodBeginDate;
        }

        const asyncProps = new AsyncDataRetrievalProps();
        asyncProps.setGenerationTs = props.setGenerationTs;
        asyncProps.recordDataRetrievalEvent = false;

        let asyncFunction: () => Promise<Iteration[] | UserStory[] | UserStory[][]> = () => {
            return Promise.resolve([] as UserStory[]);
        };
        let onSuccess: (data: Iteration[] | UserStory[] | UserStory[][]) => void = () => {
            return;
        };
        let description = "Not doing anything";

        if (readyToRetrieveUserStories()) {
            const startDate = getEarliestDate(
                props.previousMetricsPeriods,
                props.metricsPeriods[props.metricsPeriods.length - 1].startDate
            );
            const endDate = props.metricsPeriods[props.metricsPeriods.length - 1].endDate;

            asyncFunction = () => {
                return props.retrieveUserStories(startDate, endDate, props.rallyEnvironmentParser);
            };
            onSuccess = (userstories: Iteration[] | UserStory[] | UserStory[][]) => {
                props.setUserStories(userstories as UserStory[]);
            };
            description = `Accepted User Stories between ${moment(startDate).format("l")}-${moment(endDate).format(
                "l"
            )}`;
            asyncProps.recordDataRetrievalEvent = true;
        }

        asyncProps.asyncDataRetrievalFunction = asyncFunction;
        asyncProps.onSuccess = onSuccess;
        asyncProps.onError = defaultOnError;
        asyncProps.description = description;

        useAsyncDataRetrieval(
            // eslint-disable-next-line
            useMemo(() => asyncProps, [props.metricsPeriods, props.previousMetricsPeriods])
        );
    }

    function createUserStoriesForMultiplePeriodsAsyncFunction<P extends Period>(
        dataRetrievalDescription: string,
        shouldRetrieveData: (period: P) => boolean,
        retrievalMethod: (period: P, rallyEnvironmentParser: RallyEnvironmentParser) => Promise<UserStory[]>
    ): () => Promise<UserStory[][]> {
        return async () => {
            const periodUserStoryArray: UserStory[][] = [];
            for (let i = 0; i < props.metricsPeriods.length; i++) {
                periodUserStoryArray.push([]);
            }

            const promises: Promise<void>[] = [];

            for (let i = 0; i < props.metricsPeriods.length - 1; i++) {
                const p = props.metricsPeriods[i] as P;
                if (shouldRetrieveData(p)) {
                    const dataRetrievalEvent = DataRetrievalEventManager.getInstance().addDataRetrievalEvent(
                        `${dataRetrievalDescription} for period beginning ${moment(
                            props.metricsPeriods[i].startDate
                        ).format("l")}`
                    );
                    promises.push(
                        retrievalMethod(p, props.rallyEnvironmentParser)
                            .then((userStories: UserStory[]) => {
                                periodUserStoryArray[i] = userStories;
                                dataRetrievalEvent.completeEventSuccessfully();
                                return;
                            })
                            .catch((error: Error) => {
                                dataRetrievalEvent.completeEventWithError(error);
                                throw error;
                            })
                    );
                }
            }

            await Promise.all(promises);

            return periodUserStoryArray;
        };
    }

    /**
     * Dependency : Metrics Period Only, we only gather this data for the three iterations
     */
    function useUserStoriesForMultiplePeriodsAsyncDataRetrieval<P extends Period>(
        dataRetrievalDescription: string,
        shouldRetrieveData: (period: P) => boolean,
        retrievalMethod: (period: P, rallyEnvironmentParser: RallyEnvironmentParser) => Promise<UserStory[]>,
        setUserStoriesForMultiplePeriods: (userStories: UserStory[][]) => void
    ): void {
        const asyncFunction = createUserStoriesForMultiplePeriodsAsyncFunction(
            dataRetrievalDescription,
            shouldRetrieveData,
            retrievalMethod
        );

        const asyncProps = new AsyncDataRetrievalProps();
        asyncProps.setGenerationTs = props.setGenerationTs;
        asyncProps.asyncDataRetrievalFunction = asyncFunction;
        asyncProps.onSuccess = (userStories: Iteration[] | UserStory[] | UserStory[][]) => {
            setUserStoriesForMultiplePeriods(userStories as UserStory[][]);
        };
        asyncProps.onError = defaultOnError;
        asyncProps.description = "This is a composite retriever so it will not log anything";

        // eslint-disable-next-line
        useAsyncDataRetrieval(useMemo(() => asyncProps, [props.metricsPeriods]));
    }

    function metricPeriodDefined(metricPeriod: Period) {
        return !isEqual(metricPeriod, {});
    }

    function useReadyUserStoryRetriever() {
        useUserStoriesForMultiplePeriodsAsyncDataRetrieval(
            "Ready User Stories",
            metricPeriodDefined,
            props.retrieveReadyUserStories,
            props.setReadyUserStories
        );
    }

    function useCommittedUserStoryRetriever() {
        useUserStoriesForMultiplePeriodsAsyncDataRetrieval(
            "Committed User Stories",
            (period: Period) => {
                return props.selectedPlanningStyle === "Scrum" && metricPeriodDefined(period);
            },
            props.retrieveCommittedUserStories,
            props.setCommittedUserStories
        );
    }

    /****************************************
     * USE HOOKS
     ****************************************/
    renderLogger.log(JSON.stringify(props, null, 2));

    useIterationRetriever();

    usePeriodsForMetricsCalculator();

    useUserStoryRetriever();

    useReadyUserStoryRetriever();

    useCommittedUserStoryRetriever();

    return props;
}

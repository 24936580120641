import React from "react";
import { ScrumMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import MetricsDataTableScrum from "./MetricsDataTableScrum";
import MetricsDataTableFlow from "./MetricsDataTableFlow";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import MetricsDataTableBacklog from "./MetricsDataTableBacklog";
import { Period } from "rally_core/build";

export default function MetricsDataTableContainerScrum(props: MetricsDataTableContainerScrumProps): JSX.Element {
    return (
        <div data-testid="metricsDataTable">
            <MetricsDataTableScrum scrumMetrics={props.scrumMetrics} />
            <MetricsDataTableFlow flowMetrics={props.flowMetrics} />
            <MetricsDataTableBacklog backlogHealthMetrics={props.backlogHealthMetrics} />
        </div>
    );
}

export interface MetricsDataTableContainerScrumProps {
    periodLength: number;
    scrumMetrics: ScrumMetrics[];
    flowMetrics: FlowMetrics[];
    backlogHealthMetrics: BacklogHealthMetrics[];
    selectedPlanningStyle: string;
    projectId: number;
    metricsPeriods: Period[];
}

import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import React from "react";
import MetricBannerWithTrend from "./MetricBannerWithTrend";
import "./MetricsBanner.css";
import MetricBannerWithGauge from "./MetricBannerWithGauge";
import { getMetrics } from "./MetricsBannerFunctions";
import { RenderLogger } from "../utils/RenderLogger";
import { PlanningStyle } from "../PlanningStyle";

const renderLogger = new RenderLogger("MetricsBanner");

function getMetricBannerWithTrendLeadTime(props: MetricsBannerProps) {
    return (
        <MetricBannerWithTrend
            name="Lead Time"
            metric={getMetrics("current", props.flowMetrics, (metric) => (metric as FlowMetrics).averageLeadTime)}
            previousMetric={getMetrics(
                "previous",
                props.flowMetrics,
                (metric) => (metric as FlowMetrics).averageLeadTime
            )}
            numberOfDecimalPlaces={1}
            greenTrend={-1}
        />
    );
}

function getMetricBannerWithTrendCycleTime(props: MetricsBannerProps) {
    return (
        <MetricBannerWithTrend
            name="Cycle Time"
            metric={getMetrics("current", props.flowMetrics, (metric) => (metric as FlowMetrics).averageCycleTime)}
            previousMetric={getMetrics(
                "previous",
                props.flowMetrics,
                (metric) => (metric as FlowMetrics).averageCycleTime
            )}
            numberOfDecimalPlaces={1}
            greenTrend={-1}
        />
    );
}

function getMetricBannerWithTrendAcceptedStoryCount(props: MetricsBannerProps) {
    return (
        <MetricBannerWithTrend
            name="Accepted Story Count"
            metric={getMetrics("current", props.velocityMetrics, (metric) =>
                (metric as VelocityMetrics).acceptedStoryCount()
            )}
            previousMetric={getMetrics("previous", props.velocityMetrics, (metric) =>
                (metric as VelocityMetrics).acceptedStoryCount()
            )}
            numberOfDecimalPlaces={0}
            greenTrend={1}
        />
    );
}

function getMetricBannerWithTrendReadyPeriods(props: MetricsBannerProps) {
    return (
        <MetricBannerWithTrend
            name="Ready Periods"
            metric={getMetrics("current", props.velocityMetrics, (metric) =>
                (metric as VelocityMetrics).readyPeriods()
            )}
            previousMetric={getMetrics("previous", props.velocityMetrics, (metric) =>
                (metric as VelocityMetrics).readyPeriods()
            )}
            numberOfDecimalPlaces={1}
            greenTrend={1}
        />
    );
}

function getMetricBannerWithTrendVelocity(props: MetricsBannerProps) {
    return (
        <MetricBannerWithTrend
            name="Velocity"
            metric={getMetrics("current", props.velocityMetrics, (metric) => (metric as VelocityMetrics).velocity())}
            previousMetric={getMetrics("previous", props.velocityMetrics, (metric) =>
                (metric as VelocityMetrics).velocity()
            )}
            numberOfDecimalPlaces={0}
            greenTrend={1}
        />
    );
}

function MetricsBannerJSX(props: MetricsBannerProps): JSX.Element {
    renderLogger.log(JSON.stringify(props, null, 2));

    if (props.selectedPlanningStyle === PlanningStyle.SCRUM) {
        return (
            <div className="banner-container">
                {getMetricBannerWithTrendLeadTime(props)}
                {getMetricBannerWithTrendCycleTime(props)}
                <MetricBannerWithGauge
                    id="metric-gauge-commitment"
                    name="Commitment %"
                    metric={getMetrics("current", props.scrumMetrics, (metric) => {
                        const percentage = (metric as ScrumMetrics).committedPointPercentage();
                        return percentage ? percentage : 0;
                    })}
                />
                <MetricBannerWithGauge
                    id="metric-accepted-story-percent"
                    name="Accepted Story %"
                    metric={getMetrics("current", props.scrumMetrics, (metric) => {
                        const percentage = (metric as ScrumMetrics).acceptedStoryPercentage();
                        return percentage ? percentage : 0;
                    })}
                />
                <MetricBannerWithGauge
                    id="metric-accepted-point-percent"
                    name="Accepted Point %"
                    metric={getMetrics("current", props.scrumMetrics, (metric) => {
                        const percentage = (metric as ScrumMetrics).acceptedPointPercentage();
                        return percentage ? percentage : 0;
                    })}
                />
            </div>
        );
    } else if (props.selectedPlanningStyle === PlanningStyle.FLOW) {
        return (
            <div className="banner-container">
                {getMetricBannerWithTrendLeadTime(props)}
                {getMetricBannerWithTrendCycleTime(props)}
                {getMetricBannerWithTrendAcceptedStoryCount(props)}
                {getMetricBannerWithTrendVelocity(props)}
                {getMetricBannerWithTrendReadyPeriods(props)}
            </div>
        );
    }
    return <div />;
}

export interface MetricsBannerProps {
    selectedPlanningStyle: string;
    scrumMetrics: ScrumMetrics[];
    velocityMetrics: VelocityMetrics[];
    flowMetrics: FlowMetrics[];
}

const MetricsBanner = React.memo(MetricsBannerJSX);
export default MetricsBanner;

import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";

export function getMetrics(
    metricType: string,
    metric: FlowMetrics[] | ScrumMetrics[] | VelocityMetrics[],
    valFunc: (m: FlowMetrics | ScrumMetrics | VelocityMetrics) => number
): number | undefined {
    if (metric.length === 0) {
        return undefined;
    }
    if (metricType === "current") {
        return valFunc(metric[2]);
    } else {
        return valFunc(metric[1]);
    }
}

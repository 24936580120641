import React from "react";
import "./footer.css";
import { RenderLogger } from "./utils/RenderLogger";
import { exportToExcel } from "./utils/excel";
import { saveAs } from "file-saver";
import { DataRetrievalEventManager } from "./utils/DataRetrievalEvent";
import { PlanningStyle } from "./PlanningStyle";
import { DataExport } from "./utils/DataExport";
import { populatePowerpointTemplate } from "./utils/powerpoint";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import preval from "preval.macro";
import { version } from "../package.json";

const renderLogger = new RenderLogger("Footer");
const Footer = React.memo(generateFooterJSX);

const buildDate = preval`module.exports = new Date().toLocaleString()`;

function generateFooterJSX(dataExport: DataExport): JSX.Element {
    renderLogger.log();

    function writeFileToExcel(): void {
        const workbook = exportToExcel(dataExport);
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "pdo-metrics.xlsx");
        });
    }

    function writeFileToPowerpoint() {
        function generatePowerpoint(arrayBuffer: ArrayBuffer) {
            const readTemplateZip = () => {
                return new PizZip(arrayBuffer);
            };

            const writeFile = (doc: Docxtemplater) => {
                const blob = (doc.getZip() as PizZip).generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                });
                saveAs(blob, "pdo-metrics.pptx");
            };

            populatePowerpointTemplate(dataExport, readTemplateZip, writeFile);
        }

        const url =
            dataExport.selectedPlanningStyle === PlanningStyle.SCRUM
                ? `${process.env.PUBLIC_URL}/iterationStatusTemplate.pptx`
                : `${process.env.PUBLIC_URL}/periodStatusTemplate.pptx`;

        fetch(url).then((response: Response) => {
            response.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
                generatePowerpoint(arrayBuffer);
            });
        });
    }

    function dataRetrievalComplete() {
        return DataRetrievalEventManager.dataRetrievalCompleteWithoutError();
    }
    function buildNumberRetrieval() {
        return version;
    }
    function buildDateRetrieval() {
        return buildDate;
    }
    function downloadLinks() {
        if (!dataRetrievalComplete()) {
            return "";
        }

        return (
            <span>
                <a href="#!" onClick={writeFileToExcel}>
                    Excel
                </a>{" "}
                |{" "}
                <a href="#!" onClick={writeFileToPowerpoint}>
                    Powerpoint
                </a>{" "}
            </span>
        );
    }

    if (dataExport.selectedPlanningStyle === PlanningStyle.NONE) {
        return <div />;
    }

    return (
        <div>
            <div id="footer-container">
                <div id="excelDownload">{downloadLinks()}</div>
                <div id="buildDetails">
                    Version: {buildNumberRetrieval()} Build Date: {buildDateRetrieval()}
                </div>
            </div>
            <div id="footer">
                Ford Credit is now using <a href="https://github.ford.com/fdp/fourkeys">Fourkeys</a> to generate DORA
                Metrics for PDO teams{" "}
                <i>
                    <small>(previous approach is deprecated)</small>
                </i>
                . Please contact <b>devx@ford.com</b> for assistance with transition/adoption.
            </div>
        </div>
    );
}

export default Footer;

import { Cell, Workbook, Worksheet } from "exceljs";
import { Iteration, Period, UserStory } from "rally_core/build";
import { PlanningStyle } from "../PlanningStyle";
import { determinePeriodName, isAnIteration } from "./PeriodUtils";
import moment from "moment";
import { clone } from "lodash";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import { DataExport } from "./DataExport";

export function exportToExcel(dataCopy: DataExport): Workbook {
    const workbook: Workbook = new Workbook();

    createPDOMetricsWorksheet(
        workbook,
        dataCopy.selectedPlanningStyle,
        dataCopy.metricsPeriods,
        dataCopy.scrumMetrics,
        dataCopy.velocityMetrics,
        dataCopy.flowMetrics,
        dataCopy.backlogHealthMetrics
    );

    createPeriodsWorksheet(workbook, dataCopy.selectedPlanningStyle, dataCopy.iterations, dataCopy.metricsPeriods);
    createUserstoriesWorksheet(workbook, dataCopy.userStories);
    createReadyUserstoriesWorksheet(workbook, dataCopy.metricsPeriods, dataCopy.readyUserStories);

    if (dataCopy.selectedPlanningStyle === PlanningStyle.SCRUM) {
        createCommittedUserstoriesWorksheet(workbook, dataCopy.metricsPeriods, dataCopy.committedUserStories);
    }

    return workbook;
}

function createPDOMetricsWorksheet(
    workbook: Workbook,
    planningStyle: PlanningStyle,
    metricsPeriods: Period[],
    scrumMetrics: ScrumMetrics[],
    velocityMetrics: VelocityMetrics[],
    flowMetrics: FlowMetrics[],
    backlogHealthMetrics: BacklogHealthMetrics[]
): Worksheet {
    function alignRight(sheet: Worksheet, cellname: string) {
        sheet.getCell(cellname).alignment = { vertical: "bottom", horizontal: "right" };
    }

    function protectAgainstUndefined(num: number | undefined): number | string {
        if (num) {
            return num;
        } else {
            return "--";
        }
    }

    function formatAsPercentage(sheet: Worksheet, cellname: string) {
        sheet.getCell(cellname).numFmt = "0.0%";
    }

    function formatNumberWithSingleDecimalPlace(sheet: Worksheet, cellname: string) {
        sheet.getCell(cellname).numFmt = "0.0";
    }

    function createLabels(sheet: Worksheet, metricsPeriods: Period[]) {
        for (let i = 0; i < 24; i++) {
            sheet.addRow([], "n");
        }

        function makeBold(sheet: Worksheet, row: number, cell: number) {
            sheet.getRow(row).getCell(cell).style = { font: { bold: true } };
        }

        function makeBoldAndColorful(sheet: Worksheet, row: number, cell: number) {
            makeBold(sheet, row, cell);
            sheet.getRow(row).getCell(cell).font = { bold: true, color: { argb: "FF3272DC" } };
        }

        function metricsSectionsHeaders(rownum: number) {
            function thickBorder(cellnum: string) {
                const cell = sheet.getCell(cellnum);
                cell.style = { font: { bold: true } };
                cell.border = {
                    top: { style: "thick", color: { argb: "FFc8d1e0" } },
                    bottom: { style: "thick", color: { argb: "FFc8d1e0" } },
                };
            }

            sheet.mergeCells("A" + rownum + ":E" + rownum);
            thickBorder("A" + rownum);
            thickBorder("B" + rownum);
            thickBorder("C" + rownum);
            thickBorder("D" + rownum);
            thickBorder("E" + rownum);
        }

        function createHeaderRowLabels() {
            sheet.getColumn(1).width = 30;
            sheet.getColumn(2).width = 30;
            sheet.getColumn(3).width = 30;
            sheet.getColumn(4).width = 30;
            sheet.getColumn(5).width = 30;

            sheet.getRow(1).getCell(1).value = "Date Range";
            sheet.getRow(1).getCell(2).value = `${moment(metricsPeriods[0].startDate).format("l")} - ${moment(
                metricsPeriods[0].endDate
            ).format("l")}`;
            sheet.getRow(1).getCell(3).value = `${moment(metricsPeriods[1].startDate).format("l")} - ${moment(
                metricsPeriods[1].endDate
            ).format("l")}`;
            sheet.getRow(1).getCell(4).value = `${moment(metricsPeriods[2].startDate).format("l")} - ${moment(
                metricsPeriods[2].endDate
            ).format("l")}`;
            sheet.getRow(1).getCell(5).value = "Summary";

            makeBold(sheet, 1, 1);
            makeBold(sheet, 1, 2);
            makeBold(sheet, 1, 3);
            makeBold(sheet, 1, 4);
            makeBold(sheet, 1, 5);

            alignRight(sheet, "B1");
            alignRight(sheet, "C1");
            alignRight(sheet, "D1");
            alignRight(sheet, "E1");
        }

        function createScrumMetricsLabels(sheet: Worksheet) {
            sheet.getRow(2).getCell(1).value = "Scrum Metrics";
            sheet.getRow(3).getCell(1).value = "Accepted Story Count";
            sheet.getRow(4).getCell(1).value = "Committed Story Count";
            sheet.getRow(5).getCell(1).value = "Accepted Story %";
            sheet.getRow(6).getCell(1).value = "Accepted Point Count";
            sheet.getRow(7).getCell(1).value = "Committed Point Count";
            sheet.getRow(8).getCell(1).value = "Accepted Point %";
            sheet.getRow(9).getCell(1).value = "Committed Story Points Complete";
            sheet.getRow(10).getCell(1).value = "Committed Story Points";
            sheet.getRow(11).getCell(1).value = "Commitment %";
            sheet.getRow(12).getCell(1).value = "Velocity Variation %";

            metricsSectionsHeaders(2);

            makeBoldAndColorful(sheet, 3, 1);
            makeBoldAndColorful(sheet, 4, 1);
            makeBoldAndColorful(sheet, 5, 1);
            makeBoldAndColorful(sheet, 6, 1);
            makeBoldAndColorful(sheet, 7, 1);
            makeBoldAndColorful(sheet, 8, 1);
            makeBoldAndColorful(sheet, 9, 1);
            makeBoldAndColorful(sheet, 10, 1);
            makeBoldAndColorful(sheet, 11, 1);
            makeBoldAndColorful(sheet, 12, 1);
        }

        function createVelocityMetricsLabels(sheet: Worksheet) {
            sheet.getRow(2).getCell(1).value = "Scrum Metrics";
            sheet.getRow(3).getCell(1).value = "Accepted Story Count";
            sheet.getRow(4).getCell(1).value = "Velocity";
            sheet.getRow(5).getCell(1).value = "Velocity Variation %";

            metricsSectionsHeaders(2);

            makeBoldAndColorful(sheet, 3, 1);
            makeBoldAndColorful(sheet, 4, 1);
            makeBoldAndColorful(sheet, 5, 1);
        }

        function createFlowMetricsLabels(sheet: Worksheet, rowNumStart: number) {
            sheet.getRow(rowNumStart + 0).getCell(1).value = "Flow Metrics";
            sheet.getRow(rowNumStart + 1).getCell(1).value = "Lead Time (days)";
            sheet.getRow(rowNumStart + 2).getCell(1).value = "Lead Time 75% Confidence Interval";
            sheet.getRow(rowNumStart + 3).getCell(1).value = "Lead Time 90% Confidence Interval";
            sheet.getRow(rowNumStart + 4).getCell(1).value = "Cycle Time (days)";
            sheet.getRow(rowNumStart + 5).getCell(1).value = "Cycle Time 75% Confidence Interval";
            sheet.getRow(rowNumStart + 6).getCell(1).value = "Cycle Time 90% Confidence Interval";

            metricsSectionsHeaders(rowNumStart);

            makeBoldAndColorful(sheet, rowNumStart + 1, 1);
            makeBoldAndColorful(sheet, rowNumStart + 2, 1);
            makeBoldAndColorful(sheet, rowNumStart + 3, 1);
            makeBoldAndColorful(sheet, rowNumStart + 4, 1);
            makeBoldAndColorful(sheet, rowNumStart + 5, 1);
            makeBoldAndColorful(sheet, rowNumStart + 6, 1);
        }

        function createBacklogMetricsLabels(sheet: Worksheet, rowNumStart: number) {
            sheet.getRow(rowNumStart).getCell(1).value = "Backlog Metrics";
            sheet.getRow(rowNumStart + 1).getCell(1).value = "# of Ready Stories";
            sheet.getRow(rowNumStart + 2).getCell(1).value = "# of Ready Points";
            sheet.getRow(rowNumStart + 3).getCell(1).value = "# of Ready Periods";

            metricsSectionsHeaders(rowNumStart);

            makeBoldAndColorful(sheet, rowNumStart + 1, 1);
            makeBoldAndColorful(sheet, rowNumStart + 2, 1);
            makeBoldAndColorful(sheet, rowNumStart + 3, 1);
        }

        createHeaderRowLabels();

        if (planningStyle === PlanningStyle.SCRUM) {
            createScrumMetricsLabels(sheet);
        } else {
            createVelocityMetricsLabels(sheet);
        }

        createFlowMetricsLabels(sheet, planningStyle === PlanningStyle.SCRUM ? 13 : 6);

        createBacklogMetricsLabels(sheet, planningStyle === PlanningStyle.SCRUM ? 20 : 13);
    }

    function createScrumMetrics(sheet: Worksheet, scrumMetrics: ScrumMetrics[]) {
        sheet.getCell("B3").value = scrumMetrics[0].acceptedStoryCount();
        sheet.getCell("C3").value = scrumMetrics[1].acceptedStoryCount();
        sheet.getCell("D3").value = scrumMetrics[2].acceptedStoryCount();
        sheet.getCell("E3").value = scrumMetrics[3].acceptedStoryCount();

        sheet.getCell("B4").value = scrumMetrics[0].committedStoryCount();
        sheet.getCell("C4").value = scrumMetrics[1].committedStoryCount();
        sheet.getCell("D4").value = scrumMetrics[2].committedStoryCount();
        sheet.getCell("E4").value = scrumMetrics[3].committedStoryCount();

        sheet.getCell("B5").value = protectAgainstUndefined(scrumMetrics[0].acceptedStoryPercentage());
        sheet.getCell("C5").value = protectAgainstUndefined(scrumMetrics[1].acceptedStoryPercentage());
        sheet.getCell("D5").value = protectAgainstUndefined(scrumMetrics[2].acceptedStoryPercentage());
        sheet.getCell("E5").value = protectAgainstUndefined(scrumMetrics[3].acceptedStoryPercentage());

        formatAsPercentage(sheet, "B5");
        formatAsPercentage(sheet, "C5");
        formatAsPercentage(sheet, "D5");
        formatAsPercentage(sheet, "E5");

        sheet.getCell("B6").value = scrumMetrics[0].acceptedPointCount();
        sheet.getCell("C6").value = scrumMetrics[1].acceptedPointCount();
        sheet.getCell("D6").value = scrumMetrics[2].acceptedPointCount();
        sheet.getCell("E6").value = scrumMetrics[3].acceptedPointCount();

        sheet.getCell("B7").value = protectAgainstUndefined(scrumMetrics[0].committedPointCount());
        sheet.getCell("C7").value = protectAgainstUndefined(scrumMetrics[1].committedPointCount());
        sheet.getCell("D7").value = protectAgainstUndefined(scrumMetrics[2].committedPointCount());
        sheet.getCell("E7").value = protectAgainstUndefined(scrumMetrics[3].committedPointCount());

        sheet.getCell("B8").value = protectAgainstUndefined(scrumMetrics[0].acceptedPointPercentage());
        sheet.getCell("C8").value = protectAgainstUndefined(scrumMetrics[1].acceptedPointPercentage());
        sheet.getCell("D8").value = protectAgainstUndefined(scrumMetrics[2].acceptedPointPercentage());
        sheet.getCell("E8").value = protectAgainstUndefined(scrumMetrics[3].acceptedPointPercentage());

        formatAsPercentage(sheet, "B8");
        formatAsPercentage(sheet, "C8");
        formatAsPercentage(sheet, "D8");
        formatAsPercentage(sheet, "E8");

        sheet.getCell("B9").value = scrumMetrics[0].committedPointsAccepted();
        sheet.getCell("C9").value = scrumMetrics[1].committedPointsAccepted();
        sheet.getCell("D9").value = scrumMetrics[2].committedPointsAccepted();
        sheet.getCell("E9").value = scrumMetrics[3].committedPointsAccepted();

        sheet.getCell("B10").value = protectAgainstUndefined(scrumMetrics[0].committedPointCount());
        sheet.getCell("C10").value = protectAgainstUndefined(scrumMetrics[1].committedPointCount());
        sheet.getCell("D10").value = protectAgainstUndefined(scrumMetrics[2].committedPointCount());
        sheet.getCell("E10").value = protectAgainstUndefined(scrumMetrics[3].committedPointCount());

        sheet.getCell("B11").value = protectAgainstUndefined(scrumMetrics[0].committedPointPercentage());
        sheet.getCell("C11").value = protectAgainstUndefined(scrumMetrics[1].committedPointPercentage());
        sheet.getCell("D11").value = protectAgainstUndefined(scrumMetrics[2].committedPointPercentage());
        sheet.getCell("E11").value = protectAgainstUndefined(scrumMetrics[3].committedPointPercentage());

        formatAsPercentage(sheet, "B11");
        formatAsPercentage(sheet, "C11");
        formatAsPercentage(sheet, "D11");
        formatAsPercentage(sheet, "E11");

        sheet.getCell("B12").value = scrumMetrics[0].velocityVariation();
        sheet.getCell("C12").value = scrumMetrics[1].velocityVariation();
        sheet.getCell("D12").value = scrumMetrics[2].velocityVariation();
        sheet.getCell("E12").value = "--";

        formatAsPercentage(sheet, "B12");
        formatAsPercentage(sheet, "C12");
        formatAsPercentage(sheet, "D12");

        alignRight(sheet, "B3");
        alignRight(sheet, "C3");
        alignRight(sheet, "D3");
        alignRight(sheet, "E3");
        alignRight(sheet, "B4");
        alignRight(sheet, "C4");
        alignRight(sheet, "D4");
        alignRight(sheet, "E4");
        alignRight(sheet, "B5");
        alignRight(sheet, "C5");
        alignRight(sheet, "D5");
        alignRight(sheet, "E5");
        alignRight(sheet, "B6");
        alignRight(sheet, "C6");
        alignRight(sheet, "D6");
        alignRight(sheet, "E6");
        alignRight(sheet, "B7");
        alignRight(sheet, "C7");
        alignRight(sheet, "D7");
        alignRight(sheet, "E7");
        alignRight(sheet, "B8");
        alignRight(sheet, "C8");
        alignRight(sheet, "D8");
        alignRight(sheet, "E8");
        alignRight(sheet, "B9");
        alignRight(sheet, "C9");
        alignRight(sheet, "D9");
        alignRight(sheet, "E9");
        alignRight(sheet, "B10");
        alignRight(sheet, "C10");
        alignRight(sheet, "D10");
        alignRight(sheet, "E10");
        alignRight(sheet, "B11");
        alignRight(sheet, "C11");
        alignRight(sheet, "D11");
        alignRight(sheet, "E11");
        alignRight(sheet, "B12");
        alignRight(sheet, "C12");
        alignRight(sheet, "D12");
        alignRight(sheet, "E12");
    }

    function createVelocityMetrics(sheet: Worksheet, velocityMetrics: VelocityMetrics[]) {
        sheet.getCell("B3").value = velocityMetrics[0].acceptedStoryCount();
        sheet.getCell("C3").value = velocityMetrics[1].acceptedStoryCount();
        sheet.getCell("D3").value = velocityMetrics[2].acceptedStoryCount();
        sheet.getCell("E3").value = velocityMetrics[3].acceptedStoryCount();

        sheet.getCell("B4").value = velocityMetrics[0].velocity();
        sheet.getCell("C4").value = velocityMetrics[1].velocity();
        sheet.getCell("D4").value = velocityMetrics[2].velocity();
        sheet.getCell("E4").value = velocityMetrics[3].velocity();

        sheet.getCell("B5").value = velocityMetrics[0].velocityVariation();
        sheet.getCell("C5").value = velocityMetrics[1].velocityVariation();
        sheet.getCell("D5").value = velocityMetrics[2].velocityVariation();
        sheet.getCell("E5").value = "--";

        formatAsPercentage(sheet, "B5");
        formatAsPercentage(sheet, "C5");
        formatAsPercentage(sheet, "D5");

        alignRight(sheet, "B3");
        alignRight(sheet, "C3");
        alignRight(sheet, "D3");
        alignRight(sheet, "E3");
        alignRight(sheet, "B4");
        alignRight(sheet, "C4");
        alignRight(sheet, "D4");
        alignRight(sheet, "E4");
        alignRight(sheet, "B5");
        alignRight(sheet, "C5");
        alignRight(sheet, "D5");
        alignRight(sheet, "E5");
    }

    function createFlowMetrics(sheet: Worksheet, flowMetrics: FlowMetrics[], startingRow: number) {
        sheet.getCell("B" + startingRow).value = flowMetrics[0].averageLeadTime;
        sheet.getCell("C" + startingRow).value = flowMetrics[1].averageLeadTime;
        sheet.getCell("D" + startingRow).value = flowMetrics[2].averageLeadTime;
        sheet.getCell("E" + startingRow).value = flowMetrics[3].averageLeadTime;

        sheet.getCell("B" + (startingRow + 1)).value = flowMetrics[0].leadTime75;
        sheet.getCell("C" + (startingRow + 1)).value = flowMetrics[1].leadTime75;
        sheet.getCell("D" + (startingRow + 1)).value = flowMetrics[2].leadTime75;
        sheet.getCell("E" + (startingRow + 1)).value = flowMetrics[3].leadTime75;

        sheet.getCell("B" + (startingRow + 2)).value = flowMetrics[0].leadTime90;
        sheet.getCell("C" + (startingRow + 2)).value = flowMetrics[1].leadTime90;
        sheet.getCell("D" + (startingRow + 2)).value = flowMetrics[2].leadTime90;
        sheet.getCell("E" + (startingRow + 2)).value = flowMetrics[3].leadTime90;

        sheet.getCell("B" + (startingRow + 3)).value = flowMetrics[0].averageCycleTime;
        sheet.getCell("C" + (startingRow + 3)).value = flowMetrics[1].averageCycleTime;
        sheet.getCell("D" + (startingRow + 3)).value = flowMetrics[2].averageCycleTime;
        sheet.getCell("E" + (startingRow + 3)).value = flowMetrics[3].averageCycleTime;

        sheet.getCell("B" + (startingRow + 4)).value = flowMetrics[0].cycleTime75;
        sheet.getCell("C" + (startingRow + 4)).value = flowMetrics[1].cycleTime75;
        sheet.getCell("D" + (startingRow + 4)).value = flowMetrics[2].cycleTime75;
        sheet.getCell("E" + (startingRow + 4)).value = flowMetrics[3].cycleTime75;

        sheet.getCell("B" + (startingRow + 5)).value = flowMetrics[0].cycleTime90;
        sheet.getCell("C" + (startingRow + 5)).value = flowMetrics[1].cycleTime90;
        sheet.getCell("D" + (startingRow + 5)).value = flowMetrics[2].cycleTime90;
        sheet.getCell("E" + (startingRow + 5)).value = flowMetrics[3].cycleTime90;

        for (let i = 0; i < 6; i++) {
            formatNumberWithSingleDecimalPlace(sheet, "B" + (startingRow + i));
            formatNumberWithSingleDecimalPlace(sheet, "C" + (startingRow + i));
            formatNumberWithSingleDecimalPlace(sheet, "D" + (startingRow + i));
            formatNumberWithSingleDecimalPlace(sheet, "E" + (startingRow + i));
        }
    }

    function createBacklogHealthMetrics(
        sheet: Worksheet,
        backlogHealthMetrics: BacklogHealthMetrics[],
        startingRow: number
    ) {
        sheet.getCell("B" + startingRow).value = backlogHealthMetrics[0].countOfStoriesReady();
        sheet.getCell("C" + startingRow).value = backlogHealthMetrics[1].countOfStoriesReady();
        sheet.getCell("D" + startingRow).value = backlogHealthMetrics[2].countOfStoriesReady();
        sheet.getCell("E" + startingRow).value = backlogHealthMetrics[3].countOfStoriesReady();

        sheet.getCell("B" + (startingRow + 1)).value = backlogHealthMetrics[0].pointsReady;
        sheet.getCell("C" + (startingRow + 1)).value = backlogHealthMetrics[1].pointsReady;
        sheet.getCell("D" + (startingRow + 1)).value = backlogHealthMetrics[2].pointsReady;
        sheet.getCell("E" + (startingRow + 1)).value = backlogHealthMetrics[3].pointsReady;

        sheet.getCell("B" + (startingRow + 2)).value = backlogHealthMetrics[0].periodsReady;
        sheet.getCell("C" + (startingRow + 2)).value = backlogHealthMetrics[1].periodsReady;
        sheet.getCell("D" + (startingRow + 2)).value = backlogHealthMetrics[2].periodsReady;
        sheet.getCell("E" + (startingRow + 2)).value = backlogHealthMetrics[3].periodsReady;

        formatNumberWithSingleDecimalPlace(sheet, "E" + startingRow);
        formatNumberWithSingleDecimalPlace(sheet, "E" + (startingRow + 1));

        formatNumberWithSingleDecimalPlace(sheet, "B" + (startingRow + 2));
        formatNumberWithSingleDecimalPlace(sheet, "C" + (startingRow + 2));
        formatNumberWithSingleDecimalPlace(sheet, "D" + (startingRow + 2));
        formatNumberWithSingleDecimalPlace(sheet, "E" + (startingRow + 2));
    }

    const worksheet = workbook.addWorksheet("PDO Metrics");

    createLabels(worksheet, metricsPeriods);

    if (planningStyle === PlanningStyle.SCRUM) {
        createScrumMetrics(worksheet, scrumMetrics);
    } else {
        createVelocityMetrics(worksheet, velocityMetrics);
    }

    createFlowMetrics(worksheet, flowMetrics, planningStyle === PlanningStyle.SCRUM ? 14 : 7);

    createBacklogHealthMetrics(worksheet, backlogHealthMetrics, planningStyle === PlanningStyle.SCRUM ? 21 : 14);

    return worksheet;
}

function createPeriodsWorksheet(
    workbook: Workbook,
    planningStyle: PlanningStyle,
    iterations: Iteration[],
    metricsPeriods: Period[]
): Worksheet {
    const sheetName = planningStyle === PlanningStyle.SCRUM ? "Iterations" : "Periods";
    const periods = planningStyle === PlanningStyle.SCRUM ? iterations : metricsPeriods;

    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.getColumn(1).alignment = { wrapText: true };
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 12;

    addHeaderRow(worksheet, ["Name", "Start Date", "End Date"]);

    const periodsSorted = sortPeriodsInAscendingOrder(clone(periods));
    periodsSorted.forEach((period: Period) => {
        const data = [determinePeriodName(period), period.startDate, period.endDate];
        const row = worksheet.addRow(data, "n");

        formatDateCell(row.getCell(2));
        formatDateCell(row.getCell(3));
    });

    function sortPeriodsInAscendingOrder(periods: Period[]) {
        return periods.sort((p1: Period, p2: Period) => {
            return moment(p1.endDate).valueOf() - moment(p2.endDate).valueOf();
        });
    }

    return worksheet;
}

function createUserstoriesWorksheet(workbook: Workbook, userStories: UserStory[]): Worksheet {
    const worksheet = workbook.addWorksheet("User Stories");

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 60;
    worksheet.getColumn(2).alignment = { wrapText: true };
    worksheet.getColumn(3).width = 12;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(8).width = 20;

    addHeaderRow(worksheet, [
        "ID",
        "Name",
        "Size",
        "Creation Date",
        "In Progress Date",
        "Accepted Date",
        "Lead Time",
        "Cycle Time",
    ]);

    const sortedUserStories = sortUserStoriesInAscendingOrder(clone(userStories));

    sortedUserStories.forEach((userStory: UserStory) => {
        const data = [
            userStory.formattedId,
            userStory.name,
            userStory.size,
            userStory.creationDate,
            userStory.inProgressDate,
            userStory.acceptedDate,
        ];

        const row = worksheet.addRow(data, "n");

        formatDateTimeCell(row.getCell(4));
        formatDateTimeCell(row.getCell(5));
        formatDateTimeCell(row.getCell(6));

        row.getCell(7).value = {
            formula: "F" + row.number + "-D" + row.number,
            date1904: true,
        };
        row.getCell(8).value = {
            formula: "F" + row.number + "-E" + row.number,
            date1904: true,
        };
    });

    function sortUserStoriesInAscendingOrder(userStories: UserStory[]) {
        return userStories.sort((us1, us2) => {
            const us1Value = us1.acceptedDate ? moment(us1.acceptedDate).valueOf() : Number.MAX_VALUE;
            const us2Value = us2.acceptedDate ? moment(us2.acceptedDate).valueOf() : Number.MAX_VALUE;
            return us1Value - us2Value;
        });
    }

    return worksheet;
}

function getAllUserStoriesFromUserStoryPeriodArraySortedInAscendingOrderByCreationDate(
    userStoriesByPeriod: UserStory[][]
): UserStory[] {
    const userStories: UserStory[] = [];
    for (let i = 0; i < userStoriesByPeriod.length; i++) {
        for (let j = 0; j < userStoriesByPeriod[i].length; j++) {
            userStories.push(userStoriesByPeriod[i][j]);
        }
    }

    return userStories.sort((a: UserStory, b: UserStory) => a.creationDate.valueOf() - b.creationDate.valueOf());
}

function populateReadyOrCommittedUserStoriesWorksheet(
    worksheet: Worksheet,
    headers: string[],
    periods: Period[],
    userStoriesByPeriod: UserStory[][]
): void {
    worksheet.getColumn(1).width = 60;
    worksheet.getColumn(1).alignment = { wrapText: true };
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 60;
    worksheet.getColumn(3).alignment = { wrapText: true };
    worksheet.getColumn(4).width = 12;
    worksheet.getColumn(5).width = 18;
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(7).width = 18;

    addHeaderRow(worksheet, headers);

    const userStories =
        getAllUserStoriesFromUserStoryPeriodArraySortedInAscendingOrderByCreationDate(userStoriesByPeriod);

    function findPeriodForUserstory(userStory: UserStory, periods: Period[]): Period {
        let index = -1;
        for (let i = 0; i < userStoriesByPeriod.length; i++) {
            for (let j = 0; j < userStoriesByPeriod[i].length; j++) {
                if (userStoriesByPeriod[i][j] === userStory) {
                    index = i;
                    break;
                }
            }
        }
        return periods[index];
    }

    userStories.forEach((userstory) => {
        const period = findPeriodForUserstory(userstory, periods);
        const row = worksheet.addRow(
            [
                determinePeriodName(period),
                userstory.formattedId,
                userstory.name,
                userstory.size,
                userstory.creationDate,
                userstory.inProgressDate,
                userstory.acceptedDate,
            ],
            "n"
        );

        formatDateTimeCell(row.getCell(5));
        formatDateTimeCell(row.getCell(6));
        formatDateTimeCell(row.getCell(7));
    });
}

function createReadyUserstoriesWorksheet(
    workbook: Workbook,
    periods: Period[],
    readyUserStories: UserStory[][]
): Worksheet {
    const worksheet = workbook.addWorksheet("Ready User Stories");

    const periodType = isAnIteration(periods[0]) ? "Iteration" : "Period";

    populateReadyOrCommittedUserStoriesWorksheet(
        worksheet,
        ["End of " + periodType, "ID", "Name", "Size", "Creation Date", "In Progress Date", "Accepted Date"],
        periods,
        readyUserStories
    );

    return worksheet;
}

function createCommittedUserstoriesWorksheet(
    workbook: Workbook,
    periods: Period[],
    committedUserStories: UserStory[][]
): Worksheet {
    const worksheet = workbook.addWorksheet("Commitments");

    populateReadyOrCommittedUserStoriesWorksheet(
        worksheet,
        ["Iteration Commitment", "ID", "Name", "Size", "Creation Date", "In Progress Date", "Accepted Date"],
        periods,
        committedUserStories
    );

    return worksheet;
}

function addHeaderRow(worksheet: Worksheet, headers: string[]) {
    const row = worksheet.addRow(headers, "n");
    row.font = { bold: true };
}

function formatDateCell(cell: Cell) {
    cell.numFmt = "m/d/yy";
}

function formatDateTimeCell(cell: Cell) {
    cell.numFmt = "m/d/yy hh:mm:ss.000";
}

export function renderLogger(componentName: string, renderCount: number, debugInfo: string[] = []): void {
    if (!shouldLog()) {
        return;
    }

    const message = createLogMessage(componentName, renderCount, debugInfo);

    console.log(message);
}

export function createLogMessage(componentName: string, renderCount: number, debugInfo: string[] = []): string {
    let message = `Rendering Component: ${componentName}.  It has been rendered ${renderCount} times`;

    if (shouldIncludeDebug(debugInfo)) {
        message += `\n**Debug Info**`;

        for (let i = 0; i < debugInfo.length; i++) {
            message += `\n\t${debugInfo[i]}`;
        }
    }

    return message;
}

function shouldLog() {
    const strFlag = process.env.REACT_APP_RENDER_LOGGING;

    if (!strFlag) {
        return false;
    }

    try {
        const intFlag = parseInt(strFlag);
        return intFlag > 0;
    } catch (e) {
        return false;
    }
}

function shouldIncludeDebug(debugInfo: string[]) {
    if (!debugInfo || debugInfo.length === 0) {
        return false;
    }

    const strFlag = process.env.REACT_APP_RENDER_LOGGING_INCLUDE_DEBUG;

    if (!strFlag) {
        return false;
    }

    try {
        const intFlag = parseInt(strFlag);
        return intFlag > 0;
    } catch (e) {
        return false;
    }
}

export class RenderLogger {
    private renderCount = 0;
    componentName: string;

    constructor(componentName: string) {
        this.componentName = componentName;
    }

    log(...debugInfo: string[]): void {
        this.renderCount++;

        if (!shouldLog()) {
            return;
        }

        const message = createLogMessage(this.componentName, this.renderCount, debugInfo);

        console.log(message);
    }
}

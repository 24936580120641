import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "../tippy.css";
import { velocityVariation } from "./MetricsDataTableScrum";
import { VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";

export function formatAcceptedStoryCount(velocityMetric: VelocityMetrics): string {
    const acceptedStoryCount = velocityMetric ? velocityMetric.acceptedStoryCount() : 0;
    if (acceptedStoryCount === 0) {
        return "--";
    } else {
        return acceptedStoryCount.toString();
    }
}

export function formatVelocity(velocityMetric: VelocityMetrics): string {
    const acceptedStoryCount = velocityMetric ? velocityMetric.acceptedStoryCount() : 0;
    if (acceptedStoryCount === 0) {
        return "--";
    } else {
        return velocityMetric.velocity().toString();
    }
}

export default function MetricsDataTableVelocity(props: MetricsDataTableVelocityProps): JSX.Element {
    return (
        <div className="metrics-data-table">
            <div
                className="iterationmetrics-sectionheader"
                id="sectionHeaderVelocityMetrics"
                data-testid="sectionHeaderVelocityMetrics"
            >
                Velocity Metrics
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={<span>The number of stories accepted during the period</span>}
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Accepted Story Count</span>
                </Tippy>
            </div>
            <div className="data-numeric" id="acceptedStoryCount-0" data-testid="acceptedStoryCount-0">
                {formatAcceptedStoryCount(props.velocityMetrics[0])}
            </div>
            <div className="data-numeric" id="acceptedStoryCount-1" data-testid="acceptedStoryCount-1">
                {formatAcceptedStoryCount(props.velocityMetrics[1])}
            </div>
            <div className="data-numeric" id="acceptedStoryCount-2" data-testid="acceptedStoryCount-2">
                {formatAcceptedStoryCount(props.velocityMetrics[2])}
            </div>
            <div className="data-numeric" id="acceptedStoryCount-3" data-testid="acceptedStoryCount-3">
                {formatAcceptedStoryCount(props.velocityMetrics[3])}
            </div>

            <div className="row-label data-tooltip">
                <Tippy
                    content={
                        <span>
                            The number of points accepted compared to the number of points committed to. Commitment is
                            defined as the stories in the backlog 1 day after the start of the iteration.
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Velocity</span>
                </Tippy>
            </div>

            <div className="data-numeric" id="velocity-0" data-testid="velocity-0">
                {formatVelocity(props.velocityMetrics[0])}
            </div>
            <div className="data-numeric" id="velocity-1" data-testid="velocity-1">
                {formatVelocity(props.velocityMetrics[1])}
            </div>
            <div className="data-numeric" id="velocity-2" data-testid="velocity-2">
                {formatVelocity(props.velocityMetrics[2])}
            </div>
            <div className="data-numeric" id="velocity-3" data-testid="velocity-3">
                {formatVelocity(props.velocityMetrics[3])}
            </div>

            <div className="row-label data-tooltip bottom-row">
                <Tippy
                    content={
                        <span>
                            Velocity Standard Deviation of last 3 Iterations / Average Velocity Last 3 Iterations
                        </span>
                    }
                    placement="bottom-start"
                    arrow={true}
                    className="tippy-tooltip"
                >
                    <span>Velocity Variation %</span>
                </Tippy>
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-0" data-testid="velocityVariation-0">
                {velocityVariation(props.velocityMetrics[0])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-1" data-testid="velocityVariation-1">
                {velocityVariation(props.velocityMetrics[1])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-2" data-testid="velocityVariation-2">
                {velocityVariation(props.velocityMetrics[2])}
            </div>
            <div className="data-numeric bottom-row" id="velocityVariation-3" data-testid="velocityVariation-3">
                --
            </div>
        </div>
    );
}

export interface MetricsDataTableVelocityProps {
    velocityMetrics: VelocityMetrics[];
}

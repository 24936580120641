import { isNaNOrInfinity } from "./NumberFunctions";

export const NO_DATA_AVAILABLE = "--";

export function formatDecimalPlaces(val: number, numOfDecimalPlaces: number): string {
    const adjust = 10 ** numOfDecimalPlaces;
    return parseFloat(String(Math.round(val * adjust) / adjust)).toFixed(numOfDecimalPlaces);
}

export function formatPercentageWithDetails(
    percentage: number | undefined,
    numerator: number,
    denominator: number | undefined
): string {
    if (!percentage && !numerator && !denominator) {
        return NO_DATA_AVAILABLE;
    }

    const percentageText =
        !percentage || isNaNOrInfinity(percentage) ? NO_DATA_AVAILABLE : formatDecimalPlaces(percentage * 100, 1);

    const numeratorText = isNaNOrInfinity(numerator) ? NO_DATA_AVAILABLE : numerator;
    const denominatorText = !denominator || isNaNOrInfinity(denominator) ? NO_DATA_AVAILABLE : denominator;

    return `${percentageText}% (${numeratorText}/${denominatorText})`;
}

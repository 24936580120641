export const INTENTIONAL_UNIT_TEST_ERROR = "Intentional Error Created by Unit Test (Ignore 👍)";

export enum DataRetrievalStatus {
    STARTED,
    COMPLETED,
    ERROR,
    CLEARED_QUEUE = 3,
}

export class DataRetrievalEvent {
    id: number;
    description: string;
    dataRetrievalStatus: DataRetrievalStatus;
    error: undefined | Error;
    notify: (dataRetrievalEvent: DataRetrievalEvent) => void;

    static ID = 1;

    constructor(
        description: string,
        notify: (dataRetrievalEvent: DataRetrievalEvent) => void = () => {
            return;
        }
    ) {
        this.description = description;
        this.id = DataRetrievalEvent.ID++;
        this.dataRetrievalStatus = DataRetrievalStatus.STARTED;
        this.notify = notify;
    }

    completeEventSuccessfully(): void {
        this.dataRetrievalStatus = DataRetrievalStatus.COMPLETED;
        this.notify(this);
    }

    completeEventWithError(error: Error): void {
        if (error.message !== INTENTIONAL_UNIT_TEST_ERROR) {
            console.trace(error);
        }
        this.error = error;
        this.dataRetrievalStatus = DataRetrievalStatus.ERROR;
        this.notify(this);
    }

    static isDone(dataRetrievalStatus: DataRetrievalStatus): boolean {
        return dataRetrievalStatus !== DataRetrievalStatus.STARTED;
    }
}

export class DataRetrievalEventManager {
    static instance = new DataRetrievalEventManager("placeholder");

    description: string;
    dataRetrievalEvents: DataRetrievalEvent[];
    setDataRetrievalEvents: (dataRetrievalEvents: DataRetrievalEvent[]) => void;
    subscribers: ((event: DataRetrievalEvent) => void)[] = [];

    constructor(
        description = "I was never initialized",
        dataRetrievalEvents: DataRetrievalEvent[] = [],
        setDataRetrievalEvents: (dataRetrievalEvents: DataRetrievalEvent[]) => void = () => {
            return;
        }
    ) {
        this.description = description;
        this.dataRetrievalEvents = dataRetrievalEvents;
        this.setDataRetrievalEvents = setDataRetrievalEvents;
    }

    addDataRetrievalEvent(description: string = this.description): DataRetrievalEvent {
        const event = new DataRetrievalEvent(description, (dataRetrievalEvent) =>
            DataRetrievalEventManager.getInstance().eventUpdated(dataRetrievalEvent)
        );
        this.eventUpdated(event);
        return event;
    }

    static getInstance(): DataRetrievalEventManager {
        return DataRetrievalEventManager.instance;
    }

    eventUpdated(event: DataRetrievalEvent): void {
        if (event.dataRetrievalStatus === DataRetrievalStatus.STARTED) {
            this.dataRetrievalEvents.push(event);
        } else {
            this.clearQueueIfNecessary();
        }

        for (let i = 0; i < this.subscribers.length; i++) {
            this.subscribers[i](event);
        }
    }

    clearQueueIfNecessary(): void {
        let readyForCleanup = true;
        for (let i = 0; i < this.dataRetrievalEvents.length; i++) {
            if (this.dataRetrievalEvents[i].dataRetrievalStatus !== DataRetrievalStatus.COMPLETED) {
                readyForCleanup = false;
                break;
            }
        }
        if (readyForCleanup) {
            this.clearEvents();
        }
    }

    addSubscriber(notifySubscriber: (event: DataRetrievalEvent) => void): void {
        this.subscribers.push(notifySubscriber);
    }

    clearEvents(): void {
        this.dataRetrievalEvents = [];
        this.setDataRetrievalEvents(this.dataRetrievalEvents);
    }

    static dataRetrievalCompleteWithoutError(): boolean {
        const dataRetrievalEvents = DataRetrievalEventManager.getInstance().dataRetrievalEvents;

        for (let i = 0; i < dataRetrievalEvents.length; i++) {
            if (dataRetrievalEvents[i].dataRetrievalStatus === DataRetrievalStatus.ERROR) {
                return false;
            } else if (dataRetrievalEvents[i].dataRetrievalStatus === DataRetrievalStatus.STARTED) {
                return false;
            }
        }

        return true;
    }
}

import {
    Iteration,
    IterationReader,
    Period,
    RallyEnvironmentParser,
    UserStory,
    UserStoryReader,
} from "rally_core/build";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import ReactRallyEnvironmentParser from "./utils/ReactRallyEnvironmentParser";
import { PlanningStyle } from "./PlanningStyle";

export const PERIOD_END_DATE_UNDEFINED = new Date(0);
export const PERIOD_LENGTH_UNDEFINED = -1;
export const SELECTED_ITERATION_UNDEFINED = -1;

export class AppProps {
    rallyEnvironmentParser: RallyEnvironmentParser = new ReactRallyEnvironmentParser();

    retrieveIterations: (rallyEnvironmentParser: RallyEnvironmentParser) => Promise<Iteration[]> =
        IterationReader.retrieveIterations;

    retrieveUserStories: (
        begDate: Date,
        endDate: Date,
        rallyEnvironmentParser: RallyEnvironmentParser
    ) => Promise<UserStory[]> = UserStoryReader.retrieveUserStoriesForTeamWithinDateRange;

    retrieveReadyUserStories: (period: Period, rallyEnvironmentParser: RallyEnvironmentParser) => Promise<UserStory[]> =
        IterationReader.readyUserStoriesAtEndOfPeriod;

    retrieveCommittedUserStories: (
        iteration: Iteration,
        rallyEnvironmentParser: RallyEnvironmentParser
    ) => Promise<UserStory[]> = IterationReader.userStoriesCommittedToAtBeginningOfIteration;

    /**
     * STATE VARIABLES
     */
    selectedPlanningStyle: PlanningStyle = PlanningStyle.NONE;
    iterations: Iteration[] = [];
    selectedIterationId: number = SELECTED_ITERATION_UNDEFINED;
    periodEndDate: Date = PERIOD_END_DATE_UNDEFINED;
    periodLength: number = PERIOD_LENGTH_UNDEFINED;
    metricsPeriods: Period[] = [];
    previousMetricsPeriods: Period[][] = [];
    userStories: UserStory[] = [];
    readyUserStories: UserStory[][] = [];
    committedUserStories: UserStory[][] = [];
    scrumMetrics: ScrumMetrics[] = [];
    velocityMetrics: VelocityMetrics[] = [];
    flowMetrics: FlowMetrics[] = [];
    backlogHealthMetrics: BacklogHealthMetrics[] = [];
    generationTs: Date = new Date();
    /**
     * STATE SETTERS
     */
    setSelectedPlanningStyle: (v: PlanningStyle) => void = (v) => (this.selectedPlanningStyle = v);
    setIterations: (v: Iteration[]) => void = (v) => (this.iterations = v);
    setSelectedIterationId: (v: number) => void = (v) => (this.selectedIterationId = v);
    setPeriodEndDate: (v: Date) => void = (v) => (this.periodEndDate = v);
    setPeriodLength: (v: number) => void = (v) => (this.periodLength = v);
    setMetricsPeriods: (v: Period[]) => void = (v) => (this.metricsPeriods = v);
    setPreviousMetricsPeriods: (v: Period[][]) => void = (v) => (this.previousMetricsPeriods = v);
    setUserStories: (v: UserStory[]) => void = (v) => (this.userStories = v);
    setReadyUserStories: (v: UserStory[][]) => void = (v) => (this.readyUserStories = v);
    setCommittedUserStories: (v: UserStory[][]) => void = (v) => (this.committedUserStories = v);
    setScrumMetrics: (v: ScrumMetrics[]) => void = (v) => (this.scrumMetrics = v);
    setVelocityMetrics: (v: VelocityMetrics[]) => void = (v) => (this.velocityMetrics = v);
    setFlowMetrics: (v: FlowMetrics[]) => void = (v) => (this.flowMetrics = v);
    setBacklogHealthMetrics: (v: BacklogHealthMetrics[]) => void = (v) => (this.backlogHealthMetrics = v);
    setGenerationTs: (v: Date) => void = (v) => (this.generationTs = v);
}

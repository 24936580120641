import { FlowMetrics } from "pdo_metrics_calculator/build/flowMetricsCalculator";
import { formatDecimalPlaces } from "./StringFunctions";
import { BacklogHealthMetrics } from "pdo_metrics_calculator/build/backlogHealthCalculator";
import { ScrumMetrics, VelocityMetrics } from "pdo_metrics_calculator/build/velocityMetricsCalculator";
import {
    acceptedPointPercentage,
    acceptedStoryPercentage,
    committedPointPercentage,
    velocityVariation,
} from "../metrics/MetricsDataTableScrum";
import moment from "moment";
import { Period } from "rally_core/build";
import { formatPeriodRange, formatPeriodSummary } from "../metrics/MetricsDataTableHeader";
import { DataExport } from "./DataExport";
import Docxtemplater from "docxtemplater";
import { formatAcceptedStoryCount, formatVelocity } from "../metrics/MetricsDataTableVelocity";

export interface ScrumMetricsPowerpointTemplateData {
    p1AcceptedStoryP: string;
    p1AcceptedPointP: string;
    p1CommitmentP: string;
    p1VelocityVarScrum: string;
    p2AcceptedStoryP: string;
    p2AcceptedPointP: string;
    p2CommitmentP: string;
    p2VelocityVarScrum: string;
    p3AcceptedStoryP: string;
    p3AcceptedPointP: string;
    p3CommitmentP: string;
    p3VelocityVarScrum: string;
    p4AcceptedStoryP: string;
    p4AcceptedPointP: string;
    p4CommitmentP: string;
    p4VelocityVarScrum: string;
}

export class ScrumMetricsPowerpointTemplateDataImpl {
    p1AcceptedStoryP = "";
    p1AcceptedPointP = "";
    p1CommitmentP = "";
    p1VelocityVarScrum = "";
    p2AcceptedStoryP = "";
    p2AcceptedPointP = "";
    p2CommitmentP = "";
    p2VelocityVarScrum = "";
    p3AcceptedStoryP = "";
    p3AcceptedPointP = "";
    p3CommitmentP = "";
    p3VelocityVarScrum = "";
    p4AcceptedStoryP = "";
    p4AcceptedPointP = "";
    p4CommitmentP = "";
    p4VelocityVarScrum = "";

    constructor(scrumMetrics: ScrumMetrics[]) {
        this.p1AcceptedStoryP = acceptedStoryPercentage(scrumMetrics[0]);
        this.p2AcceptedStoryP = acceptedStoryPercentage(scrumMetrics[1]);
        this.p3AcceptedStoryP = acceptedStoryPercentage(scrumMetrics[2]);
        this.p4AcceptedStoryP = acceptedStoryPercentage(scrumMetrics[3]);

        this.p1AcceptedPointP = acceptedPointPercentage(scrumMetrics[0]);
        this.p2AcceptedPointP = acceptedPointPercentage(scrumMetrics[1]);
        this.p3AcceptedPointP = acceptedPointPercentage(scrumMetrics[2]);
        this.p4AcceptedPointP = acceptedPointPercentage(scrumMetrics[3]);

        this.p1CommitmentP = committedPointPercentage(scrumMetrics[0]);
        this.p2CommitmentP = committedPointPercentage(scrumMetrics[1]);
        this.p3CommitmentP = committedPointPercentage(scrumMetrics[2]);
        this.p4CommitmentP = committedPointPercentage(scrumMetrics[3]);

        this.p1VelocityVarScrum = velocityVariation(scrumMetrics[0]);
        this.p2VelocityVarScrum = velocityVariation(scrumMetrics[1]);
        this.p3VelocityVarScrum = velocityVariation(scrumMetrics[2]);
        this.p4VelocityVarScrum = "--";
    }
}

export interface VelocityMetricsPowerpointTemplateData {
    p1AcceptedStoryC: string;
    p1AcceptedPointC: string;
    p1VelocityVar: string;
    p2AcceptedStoryC: string;
    p2AcceptedPointC: string;
    p2VelocityVar: string;
    p3AcceptedStoryC: string;
    p3AcceptedPointC: string;
    p3VelocityVar: string;
    p4AcceptedStoryC: string;
    p4AcceptedPointC: string;
    p4VelocityVar: string;
}

export class VelocityMetricsPowerpointTemplateDataImpl implements VelocityMetricsPowerpointTemplateData {
    p1AcceptedPointC = "";
    p1AcceptedStoryC = "";
    p1VelocityVar = "";
    p2AcceptedPointC = "";
    p2AcceptedStoryC = "";
    p2VelocityVar = "";
    p3AcceptedPointC = "";
    p3AcceptedStoryC = "";
    p3VelocityVar = "";
    p4AcceptedPointC = "";
    p4AcceptedStoryC = "";
    p4VelocityVar = "";

    constructor(vm: VelocityMetrics[]) {
        this.p1AcceptedStoryC = formatAcceptedStoryCount(vm[0]);
        this.p2AcceptedStoryC = formatAcceptedStoryCount(vm[1]);
        this.p3AcceptedStoryC = formatAcceptedStoryCount(vm[2]);
        this.p4AcceptedStoryC = formatAcceptedStoryCount(vm[3]);

        this.p1AcceptedPointC = formatVelocity(vm[0]);
        this.p2AcceptedPointC = formatVelocity(vm[1]);
        this.p3AcceptedPointC = formatVelocity(vm[2]);
        this.p4AcceptedPointC = formatVelocity(vm[3]);

        this.p1VelocityVar = velocityVariation(vm[0]);
        this.p2VelocityVar = velocityVariation(vm[1]);
        this.p3VelocityVar = velocityVariation(vm[2]);
        this.p4VelocityVar = "--";
    }
}

export interface FlowMetricsPowerpointTemplateData {
    p1LeadTime: string;
    p1LeadTime75: string;
    p1LeadTime90: string;
    p1CycleTime: string;
    p1CycleTime75: string;
    p1CycleTime90: string;

    p2LeadTime: string;
    p2LeadTime75: string;
    p2LeadTime90: string;
    p2CycleTime: string;
    p2CycleTime75: string;
    p2CycleTime90: string;

    p3LeadTime: string;
    p3LeadTime75: string;
    p3LeadTime90: string;
    p3CycleTime: string;
    p3CycleTime75: string;
    p3CycleTime90: string;

    p4LeadTime: string;
    p4LeadTime75: string;
    p4LeadTime90: string;
    p4CycleTime: string;
    p4CycleTime75: string;
    p4CycleTime90: string;
}

export class FlowMetricsPowerpointTemplateDataImpl implements FlowMetricsPowerpointTemplateData {
    p1LeadTime: string;
    p1LeadTime75: string;
    p1LeadTime90: string;
    p1CycleTime: string;
    p1CycleTime75: string;
    p1CycleTime90: string;

    p2LeadTime: string;
    p2LeadTime75: string;
    p2LeadTime90: string;
    p2CycleTime: string;
    p2CycleTime75: string;
    p2CycleTime90: string;

    p3LeadTime: string;
    p3LeadTime75: string;
    p3LeadTime90: string;
    p3CycleTime: string;
    p3CycleTime75: string;
    p3CycleTime90: string;

    p4LeadTime: string;
    p4LeadTime75: string;
    p4LeadTime90: string;
    p4CycleTime: string;
    p4CycleTime75: string;
    p4CycleTime90: string;

    constructor(flowMetrics: FlowMetrics[]) {
        this.p1LeadTime = formatDecimalPlaces(flowMetrics[0].averageLeadTime, 1);
        this.p2LeadTime = formatDecimalPlaces(flowMetrics[1].averageLeadTime, 1);
        this.p3LeadTime = formatDecimalPlaces(flowMetrics[2].averageLeadTime, 1);
        this.p4LeadTime = formatDecimalPlaces(flowMetrics[3].averageLeadTime, 1);

        this.p1LeadTime75 = formatDecimalPlaces(flowMetrics[0].leadTime75, 1);
        this.p2LeadTime75 = formatDecimalPlaces(flowMetrics[1].leadTime75, 1);
        this.p3LeadTime75 = formatDecimalPlaces(flowMetrics[2].leadTime75, 1);
        this.p4LeadTime75 = formatDecimalPlaces(flowMetrics[3].leadTime75, 1);

        this.p1LeadTime90 = formatDecimalPlaces(flowMetrics[0].leadTime90, 1);
        this.p2LeadTime90 = formatDecimalPlaces(flowMetrics[1].leadTime90, 1);
        this.p3LeadTime90 = formatDecimalPlaces(flowMetrics[2].leadTime90, 1);
        this.p4LeadTime90 = formatDecimalPlaces(flowMetrics[3].leadTime90, 1);

        this.p1CycleTime = formatDecimalPlaces(flowMetrics[0].averageCycleTime, 1);
        this.p2CycleTime = formatDecimalPlaces(flowMetrics[1].averageCycleTime, 1);
        this.p3CycleTime = formatDecimalPlaces(flowMetrics[2].averageCycleTime, 1);
        this.p4CycleTime = formatDecimalPlaces(flowMetrics[3].averageCycleTime, 1);

        this.p1CycleTime75 = formatDecimalPlaces(flowMetrics[0].cycleTime75, 1);
        this.p2CycleTime75 = formatDecimalPlaces(flowMetrics[1].cycleTime75, 1);
        this.p3CycleTime75 = formatDecimalPlaces(flowMetrics[2].cycleTime75, 1);
        this.p4CycleTime75 = formatDecimalPlaces(flowMetrics[3].cycleTime75, 1);

        this.p1CycleTime90 = formatDecimalPlaces(flowMetrics[0].cycleTime90, 1);
        this.p2CycleTime90 = formatDecimalPlaces(flowMetrics[1].cycleTime90, 1);
        this.p3CycleTime90 = formatDecimalPlaces(flowMetrics[2].cycleTime90, 1);
        this.p4CycleTime90 = formatDecimalPlaces(flowMetrics[3].cycleTime90, 1);
    }
}

export interface BacklogHealthMetricsPowerpointTemplateData {
    p1ReadyStories: string;
    p1ReadyPoints: string;
    p1ReadyPeriods: string;
    p2ReadyStories: string;
    p2ReadyPoints: string;
    p2ReadyPeriods: string;
    p3ReadyStories: string;
    p3ReadyPoints: string;
    p3ReadyPeriods: string;
    p4ReadyStories: string;
    p4ReadyPoints: string;
    p4ReadyPeriods: string;
}

export class BacklogHealthMetricsPowerpointTemplateDataImpl implements BacklogHealthMetricsPowerpointTemplateData {
    p1ReadyPeriods = "";
    p1ReadyPoints = "";
    p1ReadyStories = "";
    p2ReadyPeriods = "";
    p2ReadyPoints = "";
    p2ReadyStories = "";
    p3ReadyPeriods = "";
    p3ReadyPoints = "";
    p3ReadyStories = "";
    p4ReadyPeriods = "";
    p4ReadyPoints = "";
    p4ReadyStories = "";

    constructor(backlogHealthMetrics: BacklogHealthMetrics[]) {
        this.p1ReadyStories = formatDecimalPlaces(backlogHealthMetrics[0].countOfStoriesReady(), 0);
        this.p2ReadyStories = formatDecimalPlaces(backlogHealthMetrics[1].countOfStoriesReady(), 0);
        this.p3ReadyStories = formatDecimalPlaces(backlogHealthMetrics[2].countOfStoriesReady(), 0);
        this.p4ReadyStories = formatDecimalPlaces(backlogHealthMetrics[3].countOfStoriesReady(), 0);

        this.p1ReadyPoints = formatDecimalPlaces(backlogHealthMetrics[0].pointsReady, 0);
        this.p2ReadyPoints = formatDecimalPlaces(backlogHealthMetrics[1].pointsReady, 0);
        this.p3ReadyPoints = formatDecimalPlaces(backlogHealthMetrics[2].pointsReady, 0);
        this.p4ReadyPoints = formatDecimalPlaces(backlogHealthMetrics[3].pointsReady, 0);

        this.p1ReadyPeriods = formatDecimalPlaces(backlogHealthMetrics[0].periodsReady, 1);
        this.p2ReadyPeriods = formatDecimalPlaces(backlogHealthMetrics[1].periodsReady, 1);
        this.p3ReadyPeriods = formatDecimalPlaces(backlogHealthMetrics[2].periodsReady, 1);
        this.p4ReadyPeriods = formatDecimalPlaces(backlogHealthMetrics[3].periodsReady, 1);
    }
}

export interface PowerpointTemplateData {
    dategenerated: string;
    tsgenerated: string;
    p1Range: string;
    p2Range: string;
    p3Range: string;
    p4Range: string;
}

export class PowerpointTemplateDataImpl implements PowerpointTemplateData {
    dategenerated: string;
    p1Range: string;
    p2Range: string;
    p3Range: string;
    p4Range: string;
    tsgenerated: string;

    constructor(generationTs: Date, periods: Period[]) {
        this.dategenerated = moment(generationTs).format("l");
        this.tsgenerated = moment(generationTs).format("LLL");
        this.p1Range = formatPeriodRange(periods[0]);
        this.p2Range = formatPeriodRange(periods[1]);
        this.p3Range = formatPeriodRange(periods[2]);
        this.p4Range = formatPeriodSummary(periods);
    }
}

export interface ScrumPowerpointTemplateData
    extends PowerpointTemplateData,
        ScrumMetricsPowerpointTemplateData,
        FlowMetricsPowerpointTemplateData,
        BacklogHealthMetricsPowerpointTemplateData {}

export function populatePowerpointTemplate(
    dataExport: DataExport,
    readTemplateZip: () => PizZip | PizZip.LoadData,
    writeFile: (doc: Docxtemplater) => void
): void {
    function generateTemplateData(dataExport: DataExport): ScrumPowerpointTemplateData {
        const powerpointTemplate = new PowerpointTemplateDataImpl(dataExport.generationTs, dataExport.metricsPeriods);
        const scrumMetricsPowerpointTemplate = new ScrumMetricsPowerpointTemplateDataImpl(dataExport.scrumMetrics);
        const velocityMetricsPowerpointTemplate = new VelocityMetricsPowerpointTemplateDataImpl(
            dataExport.velocityMetrics
        );
        const flowMetricsTemplate = new FlowMetricsPowerpointTemplateDataImpl(dataExport.flowMetrics);
        const backlogHealthMetricsTemplate = new BacklogHealthMetricsPowerpointTemplateDataImpl(
            dataExport.backlogHealthMetrics
        );
        return {
            ...powerpointTemplate,
            ...scrumMetricsPowerpointTemplate,
            ...velocityMetricsPowerpointTemplate,
            ...flowMetricsTemplate,
            ...backlogHealthMetricsTemplate,
        };
    }

    const templateContent = readTemplateZip();

    let doc;
    try {
        doc = new Docxtemplater(templateContent);
    } catch (error) {
        console.trace("Error occurred while generating powerpoint output");
        throw error;
    }

    const templateData: ScrumPowerpointTemplateData = generateTemplateData(dataExport);
    doc.setData(templateData);

    try {
        doc.render();
    } catch (error) {
        console.trace(error);
        throw error;
    }

    writeFile(doc);
}

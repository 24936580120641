import { RallyEnvironmentParser, RallyUtils } from "rally_core/build";

export default class ReactRallyEnvironmentParser extends RallyEnvironmentParser {
    environment: string;
    zsessionid: string;
    projectId: number;
    iterationId: number;
    workspaceId: number;
    proxyHost: string;
    proxyPort: number;

    constructor() {
        super();
        this.environment = process.env["NODE_ENV"];
        this.workspaceId = this.setWorkSpaceIdFromEnvironmentVariableOrFromRally();
        this.projectId = this.setProjectIdFromEnvironmentVariableOrFromRally();
        this.zsessionid = this.setZsessionIdFromEnvironmentVariableOrFromRally();
        this.iterationId = this.parseIntFromEnvironmentVariable("REACT_APP_PRESET_ITERATION");
        this.proxyHost = this.stringEnvironmentVariable("REACT_APP_AXIOS_PROXY_HOST");
        this.proxyPort = this.parseIntFromEnvironmentVariable("REACT_APP_AXIOS_PROXY_PORT");
    }

    private setZsessionIdFromEnvironmentVariableOrFromRally() {
        let zsessionId = this.stringEnvironmentVariable("REACT_APP_RALLY_ZSESSIONID");
        if (zsessionId === undefined) {
            zsessionId = RallyUtils.getRallySessionCookie(this);
        }
        return zsessionId;
    }

    private setProjectIdFromEnvironmentVariableOrFromRally() {
        let projectId = this.parseIntFromEnvironmentVariable("REACT_APP_PRESET_PROJECT");
        if (projectId === -1) {
            projectId = RallyUtils.getProjectIdFromRallyUrl();
        }
        return projectId;
    }

    private setWorkSpaceIdFromEnvironmentVariableOrFromRally() {
        let workSpaceId = this.parseIntFromEnvironmentVariable("REACT_APP_PRESET_WORKSPACE");
        if (workSpaceId === -1) {
            workSpaceId = 34494663231;
        }
        return workSpaceId;
    }

    private stringEnvironmentVariable(varName: string): string {
        const val = process.env[varName];
        if (!val) {
            return "";
        }

        return val;
    }

    private parseIntFromEnvironmentVariable(varName: string) {
        const val = process.env[varName];

        if (!val) {
            return -1;
        }

        return parseInt(val);
    }
}

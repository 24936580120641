import { RenderLogger } from "../utils/RenderLogger";
import React, { useEffect, useRef } from "react";
import "c3/c3.css";
import { ChartGenerator, GaugeChartWriterProps } from "../charts/ChartGenerator";

function MetricBannerWithGaugeJSX(props: MetricBannerWithGaugeProps): JSX.Element {
    const renderLogger = useRef(new RenderLogger("MetricBannerWithGauge - " + props.name));

    renderLogger.current.log();

    useEffect(() => {
        clearDiv(props.id);

        if (props.metric) {
            ChartGenerator.getInstance().writeGaugeChart(props as GaugeChartWriterProps);
        }

        //cleanup
        return () => {
            clearDiv(props.id);
        };
    });

    return (
        <div className="banner-item">
            <div className="banner-visual" id={props.id}>
                <span className="banner-metric insight-neutral">--</span>
            </div>
            <div className="banner-label">
                <span data-testid="name">{props.name}</span>
            </div>
        </div>
    );
}

export interface MetricBannerWithGaugeProps {
    id: string;
    name: string;
    metric: number | undefined;
}

function clearDiv(id: string) {
    const element = document.getElementById(id);
    if (element) {
        element.innerHTML = '<span class="banner-metric insight-neutral">--</span>';
    }
}

const MetricBannerWithGauge = React.memo(MetricBannerWithGaugeJSX);
export default MetricBannerWithGauge;
